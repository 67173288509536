export const search: {
	SEARCH_FILTER_REQUEST: "SEARCH_FILTER_REQUEST";
	SEARCH_FILTER_RESPONSE: "SEARCH_FILTER_RESPONSE";
	PAGINATE_SEARCH_RESULTS: "PAGINATE_SEARCH_RESULTS";
	UPDATE_PAGINATION: "UPDATE_PAGINATION";
	PACKAGING_SEARCH_RESPONSE: "PACKAGING_SEARCH_RESPONSE";
	UPDATE_PACKAGING_PAGINATION: "UPDATE_PACKAGING_PAGINATION";
	SET_SEARCH_FILTER: "SET_SEARCH_FILTER";
	SET_SEARCH_TERM: "SET_SEARCH_TERM";
	SET_SEARCH_LOADING: "SET_SEARCH_LOADING";
	SET_PACKAGE_SEARCH_TERM: "SET_PACKAGE_SEARCH_TERM";
	SET_PACKAGE_SEARCH_LOADING: "SET_PACKAGE_SEARCH_LOADING";
	PORTFOLIO_PRODUCTS_SEARCH_RESPONSE: "PORTFOLIO_PRODUCTS_SEARCH_RESPONSE";
} = {
	SEARCH_FILTER_REQUEST: "SEARCH_FILTER_REQUEST",
	SEARCH_FILTER_RESPONSE: "SEARCH_FILTER_RESPONSE",
	PAGINATE_SEARCH_RESULTS: "PAGINATE_SEARCH_RESULTS",
	UPDATE_PAGINATION: "UPDATE_PAGINATION",
	PACKAGING_SEARCH_RESPONSE: "PACKAGING_SEARCH_RESPONSE",
	UPDATE_PACKAGING_PAGINATION: "UPDATE_PACKAGING_PAGINATION",
	SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
	SET_SEARCH_TERM: "SET_SEARCH_TERM",
	SET_SEARCH_LOADING: "SET_SEARCH_LOADING",
	SET_PACKAGE_SEARCH_TERM: "SET_PACKAGE_SEARCH_TERM",
	SET_PACKAGE_SEARCH_LOADING: "SET_PACKAGE_SEARCH_LOADING",
	PORTFOLIO_PRODUCTS_SEARCH_RESPONSE: "PORTFOLIO_PRODUCTS_SEARCH_RESPONSE"
};

export const user: {
	LOGIN_REQUEST: "LOGIN_REQUEST";
	LOGIN_RESPONSE: "LOGIN_RESPONSE";
	LOGOUT_REQUEST: "LOGOUT_REQUEST";
	PROFILE_RESPONSE: "PROFILE_RESPONSE";
	USER_PREFERENCE_RESPONSE: "USER_PREFERENCE_RESPONSE";
	GET_ALL_COMPANY_USERS: "GET_ALL_COMPANY_USERS";
	SELECT_BRAND: "SELECT_BRAND";
} = {
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_RESPONSE: "LOGIN_RESPONSE",
	LOGOUT_REQUEST: "LOGOUT_REQUEST",
	PROFILE_RESPONSE: "PROFILE_RESPONSE",
	USER_PREFERENCE_RESPONSE: "USER_PREFERENCE_RESPONSE",
	GET_ALL_COMPANY_USERS: "GET_ALL_COMPANY_USERS",
	SELECT_BRAND: "SELECT_BRAND"
};

export const route = {
	CHANGE_ROUTE: "CHANGE_ROUTE"
};

export const admin: {
	PENDING_USER_REQUEST: "PENDING_USER_REQUEST";
	PENDING_USER_RESPONSE: "PENDING_USER_RESPONSE";
	ADMIN_PRODUCTS_RESPONSE: "ADMIN_PRODUCTS_RESPONSE";
	SELECT_PRODUCT: "SELECT_PRODUCT";
	PAGINATE_ADMIN_PRODUCT: "PAGINATE_ADMIN_PRODUCT";
	ADMIN_REPORT_REQUESTS_RESPONSE: "ADMIN_REPORT_REQUESTS_RESPONSE";
	PAGINATE_ADMIN_REPORT_REQUEST: "PAGINATE_ADMIN_REPORT_REQUEST";
	ADMIN_REPORTS_RESPONSE: "ADMIN_REPORTS_RESPONSE";
	PAGINATE_ADMIN_REPORTS: "PAGINATE_ADMIN_REPORTS";
	EMAIL_SCHEDULE_RESPONSE: "EMAIL_SCHEDULE_RESPONSE";
	ALL_USER_RESPONSE: "ALL_USER_RESPONSE";
} = {
	PENDING_USER_REQUEST: "PENDING_USER_REQUEST",
	PENDING_USER_RESPONSE: "PENDING_USER_RESPONSE",
	ADMIN_PRODUCTS_RESPONSE: "ADMIN_PRODUCTS_RESPONSE",
	SELECT_PRODUCT: "SELECT_PRODUCT",
	PAGINATE_ADMIN_PRODUCT: "PAGINATE_ADMIN_PRODUCT",
	ADMIN_REPORT_REQUESTS_RESPONSE: "ADMIN_REPORT_REQUESTS_RESPONSE",
	PAGINATE_ADMIN_REPORT_REQUEST: "PAGINATE_ADMIN_REPORT_REQUEST",
	ADMIN_REPORTS_RESPONSE: "ADMIN_REPORTS_RESPONSE",
	PAGINATE_ADMIN_REPORTS: "PAGINATE_ADMIN_REPORTS",
	EMAIL_SCHEDULE_RESPONSE: "EMAIL_SCHEDULE_RESPONSE",
	ALL_USER_RESPONSE: "ALL_USER_RESPONSE"
};

export const preference: {
	PREFERENCE_REQUEST: "PREFERENCE_REQUEST";
	PREFERENCE_RESPONSE: "PREFERENCE_RESPONSE";
} = {
	PREFERENCE_REQUEST: "PREFERENCE_REQUEST",
	PREFERENCE_RESPONSE: "PREFERENCE_RESPONSE"
};

export const ingredients: {
	INGREDIENTS_RESPONSE: "INGREDIENTS_REQUEST";
	INGREDIENTS_SEARCH_RESPONSE: "INGREDIENT_SEARCH_RESPONSE";
	SET_INGREDIENT_PAGINATION: "SET_INGREDIENT_PAGINATION";
	PAGINATE_INGREDIENTS: "PAGINATE_INGREDIENTS";
	GET_STARRED_INGREDIENTS_RESPONSE: "GET_STARRED_INGREDIENTS_RESPONSE";
	STARRED_INGREDIENTS_RESPONSE: "STARRED_INGREDIENTS_RESPONSE";
	GET_INGREDIENT_DETAIL_RESPONSE: "GET_INGREDIENT_DETAIL_RESPONSE";
	GET_INGREDIENT_PERSONA_RESPONSE: "GET_INGREDIENT_PERSONA_RESPONSE";
	GET_SIMILIAR_INGREDIENTS_RESPONSE: "GET_SIMILIAR_INGREDIENTS_RESPONSE";
	GET_INGREDIENT_STATUS_RESPONSE: "GET_INGREDIENT_STATUS_RESPONSE";
	GET_RECENTLY_VIEWED_INGREDIENT_RESPONSE: "GET_RECENTLY_VIEWED_INGREDIENT_RESPONSE";
	GET_INGREDIENT_USAGE: "GET_INGREDIENT_USAGE";
	GET_BUYERS_PREVIEW: "GET_BUYERS_PREVIEW";
} = {
	INGREDIENTS_RESPONSE: "INGREDIENTS_REQUEST",
	SET_INGREDIENT_PAGINATION: "SET_INGREDIENT_PAGINATION",
	PAGINATE_INGREDIENTS: "PAGINATE_INGREDIENTS",
	GET_STARRED_INGREDIENTS_RESPONSE: "GET_STARRED_INGREDIENTS_RESPONSE",
	STARRED_INGREDIENTS_RESPONSE: "STARRED_INGREDIENTS_RESPONSE",
	GET_INGREDIENT_DETAIL_RESPONSE: "GET_INGREDIENT_DETAIL_RESPONSE",
	GET_INGREDIENT_PERSONA_RESPONSE: "GET_INGREDIENT_PERSONA_RESPONSE",
	GET_SIMILIAR_INGREDIENTS_RESPONSE: "GET_SIMILIAR_INGREDIENTS_RESPONSE",
	GET_INGREDIENT_STATUS_RESPONSE: "GET_INGREDIENT_STATUS_RESPONSE",
	GET_RECENTLY_VIEWED_INGREDIENT_RESPONSE: "GET_RECENTLY_VIEWED_INGREDIENT_RESPONSE",
	INGREDIENTS_SEARCH_RESPONSE: "INGREDIENT_SEARCH_RESPONSE",
	GET_INGREDIENT_USAGE: "GET_INGREDIENT_USAGE",
	GET_BUYERS_PREVIEW: "GET_BUYERS_PREVIEW"
};

export const company: {
	GET_COMPANY_RESPONSE: "GET_COMPANY_RESPONSE";
} = {
	GET_COMPANY_RESPONSE: "GET_COMPANY_RESPONSE"
};

export const integration: {
	GET_INTEGRATIONS_RESPONSE: "GET_INTEGRATIONS_RESPONSE";
	GET_ALL_REQUESTS_RESPONSE: "GET_ALL_REQUESTS_RESPONSE";
} = {
	GET_INTEGRATIONS_RESPONSE: "GET_INTEGRATIONS_RESPONSE",
	GET_ALL_REQUESTS_RESPONSE: "GET_ALL_REQUESTS_RESPONSE"
};

export const dashboard: {
	GET_DASHBOARD_OVERVIEW_RESPONSE: "GET_DASHBOARD_OVERVIEW_RESPONSE";
	GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE: "GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE";
	GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE: "GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE";
	GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE: "GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE";
} = {
	GET_DASHBOARD_OVERVIEW_RESPONSE: "GET_DASHBOARD_OVERVIEW_RESPONSE",
	GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE: "GET_DASHBOARD_PRODUCT_ANALYTICS_RESPONSE",
	GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE: "GET_DASHBOARD_ACTIVE_PRODUCT_RESPONSE",
	GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE: "GET_DASHBOARD_CONCEPT_PRODUCT_RESPONSE"
};

export const product: {
	GET_ACTIVE_PRODUCT_RESPONSE: "GET_ACTIVE_PRODUCT_RESPONSE";
	GET_CONCEPT_PRODUCT_RESPONSE: "GET_CONCEPT_PRODUCT_RESPONSE";
	GET_PRODUCT_DETAIL_RESPONSE: "GET_PRODUCT_DETAIL_RESPONSE";
	GET_PRODUCT_PERSONA_RESPONSE: "GET_PRODUCT_PERSONA_RESPONSE";
	PAGINATE_CONCEPT_PRODUCT: "PAGINATE_CONCEPT_PRODUCT";
	PAGINATE_ACTIVE_PRODUCT: "PAGINATE_ACTIVE_PRODUCT";
	GET_PRODUCT_VERSIONS_RESPONSE: "GET_PRODUCT_VERSIONS_RESPONSE";
	GET_PRODUCT_PACKAGES_RESPONSE: "GET_PRODUCT_PACKAGES_RESPONSE";
	GET_RECENTLY_VIEWED_PRODUCT_RESPONSE: "GET_RECENTLY_VIEWED_PRODUCT_RESPONSE";
} = {
	GET_ACTIVE_PRODUCT_RESPONSE: "GET_ACTIVE_PRODUCT_RESPONSE",
	GET_CONCEPT_PRODUCT_RESPONSE: "GET_CONCEPT_PRODUCT_RESPONSE",
	GET_PRODUCT_DETAIL_RESPONSE: "GET_PRODUCT_DETAIL_RESPONSE",
	PAGINATE_CONCEPT_PRODUCT: "PAGINATE_CONCEPT_PRODUCT",
	PAGINATE_ACTIVE_PRODUCT: "PAGINATE_ACTIVE_PRODUCT",
	GET_PRODUCT_PERSONA_RESPONSE: "GET_PRODUCT_PERSONA_RESPONSE",
	GET_PRODUCT_VERSIONS_RESPONSE: "GET_PRODUCT_VERSIONS_RESPONSE",
	GET_PRODUCT_PACKAGES_RESPONSE: "GET_PRODUCT_PACKAGES_RESPONSE",
	GET_RECENTLY_VIEWED_PRODUCT_RESPONSE: "GET_RECENTLY_VIEWED_PRODUCT_RESPONSE"
};

export const persona: {
	GET_ALL_PERSONAS_RESPONSE: "GET_ALL_PERSONAS_RESPONSE";
} = {
	GET_ALL_PERSONAS_RESPONSE: "GET_ALL_PERSONAS_RESPONSE"
};

export const notification: {
	GET_ALL_NOTIFICATIONS_RESPONSE: "GET_ALL_NOTIFICATIONS_RESPONSE";
} = {
	GET_ALL_NOTIFICATIONS_RESPONSE: "GET_ALL_NOTIFICATIONS_RESPONSE"
};

export const packaging: {
	GET_ALL_PACKAGES_RESPONSE: "GET_ALL_PACKAGES_RESPONSE";
	UPDATE_PAGINATION: "UPDATE_PAGINATION";
	SELECT_PACKAGE: "SELECT_PACKAGE";
} = {
	GET_ALL_PACKAGES_RESPONSE: "GET_ALL_PACKAGES_RESPONSE",
	UPDATE_PAGINATION: "UPDATE_PAGINATION",
	SELECT_PACKAGE: "SELECT_PACKAGE"
};

export const report: {
	GET_ALL_REPORTS_RESPONSE: "GET_ALL_REPORTS_RESPONSE";
	PAGINATE_REPORTS: "PAGINATE_REPORTS";
	GET_ALL_REPORT_REQUESTS_RESPONSE: "GET_ALL_REPORT_REQUESTS_RESPONSE";
} = {
	GET_ALL_REPORTS_RESPONSE: "GET_ALL_REPORTS_RESPONSE",
	PAGINATE_REPORTS: "PAGINATE_REPORTS",
	GET_ALL_REPORT_REQUESTS_RESPONSE: "GET_ALL_REPORT_REQUESTS_RESPONSE"
};

export const recommendations: {
	GET_ALL_RECOMMENDATIONS_RESPONSE: "GET_ALL_RECOMMENDATIONS_RESPONSE";
} = {
	GET_ALL_RECOMMENDATIONS_RESPONSE: "GET_ALL_RECOMMENDATIONS_RESPONSE"
};

export const analytics: {
	GET_REPORTS: "GET_REPORTS";
	GET_TIME_SAVED: "GET_TIME_SAVED";
	FILTER_TIME_SAVED: "FILTER_TIME_SAVED";
	GET_PRODUCT_CHANGES: "GET_PRODUCT_CHANGES";
	FILTER_PRODUCT_CHANGES: "FILTER_PRODUCT_CHANGES";
} = {
	GET_REPORTS: "GET_REPORTS",
	GET_TIME_SAVED: "GET_TIME_SAVED",
	FILTER_TIME_SAVED: "FILTER_TIME_SAVED",
	GET_PRODUCT_CHANGES: "GET_PRODUCT_CHANGES",
	FILTER_PRODUCT_CHANGES: "FILTER_PRODUCT_CHANGES"
};

export const product_types: {
	GET_ALL_PRODUCT_TYPES: "GET_ALL_PRODUCT_TYPES";
} = {
	GET_ALL_PRODUCT_TYPES: "GET_ALL_PRODUCT_TYPES"
};

export const billing: {
	GET_ALL_INVOICES: "GET_ALL_INVOICES";
	GET_COMPANY_INVOICES: "GET_USER_INVOICES";
	GET_INVOICE: "GET_INVOICE";
} = {
	GET_ALL_INVOICES: "GET_ALL_INVOICES",
	GET_COMPANY_INVOICES: "GET_USER_INVOICES",
	GET_INVOICE: "GET_INVOICE"
};

export const supplier: {
	GET_ALL_SUPPLIER_RESPONSE: "GET_ALL_SUPPLIER_RESPONSE";
	GET_SUPPLIER_INGREDIENT: "GET_SUPPLIER_INGREDIENT";
	PAGINATE_SUPPLIER_INGREDIENT: "PAGINATE_SUPPLIER_INGREDIENT";
} = {
	GET_ALL_SUPPLIER_RESPONSE: "GET_ALL_SUPPLIER_RESPONSE",
	GET_SUPPLIER_INGREDIENT: "GET_SUPPLIER_INGREDIENT",
	PAGINATE_SUPPLIER_INGREDIENT: "PAGINATE_SUPPLIER_INGREDIENT"
};
