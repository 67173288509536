import React, { useEffect, useState } from "react";
import {
	Button,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Table
} from "reactstrap";
import { FileText, Loader, Plus, Upload, X } from "react-feather";
import { Dispatch } from "redux";
import reportService from "../../../services/reportService";
import { connect } from "react-redux";
import { paginateReports } from "../../../redux/actions/reports/reports";
import { IApplicationState } from "../../../redux/reducers";
import { IReport } from "../../../interfaces/report";
import { IPagination } from "../../../interfaces/pagination";
import { SAMPLE_REPORTS } from "../reports/sampleReports";
import ReportRow from "../../analysis/components/reportTableRow";
import { REPORTS_ALLOWED_FILE_EXTENSIONS } from "../../../utils/constants";
import { error } from "../../../utils/notification";

interface IProps {
	reports: IReport[];
	requests: IReport[];
	pagination: IPagination;
	user_email: string | undefined;
	role?: string;
	paginateReports: (page: number) => void;
	getAllReportRequest: () => Promise<any>;
	getAllReports: () => Promise<any>;
	addReport: (reportType: string, reportFile: File, reportDescription: string) => Promise<any>;
	addReportRequest: (reportType: string, reportDescription: string) => Promise<any>;
}

const FILE_SUPPORTED = [
	"pdf",
	"jpg",
	"jpeg",
	"png",
	"csv",
	"vnd.ms-excel",
	"vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

const Labels: React.FC<IProps> = ({
	reports,
	role,
	user_email,
	paginateReports,
	getAllReports,
	addReport
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [reportFile, setReportFile] = useState<File>();
	const [reportType, setReportType] = useState<string>();
	const [reportDescription, setReportDescription] = useState<string>();
	const [adding, setAdding] = useState<boolean>();
	const [isFormatValid, setIsFormatValid] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>(
		"File format supported : pdf, excel, csv, jpg and png."
	);

	function toggleAddForm() {
		setIsFormatValid(false);
		setErrorMessage("File format supported : pdf, excel, csv, jpg and png.");
		setModal(!modal);
	}

	function selectReportFile(event: any) {
		if (event.target.files[0]) {
			console.log(event.target.files[0]);
			const type = event.target.files[0].type.split("/")[1];
			if (!FILE_SUPPORTED.includes(type)) {
				setIsFormatValid(false);
				setErrorMessage("File type not supported.");
			} else {
				setIsFormatValid(true);
				setErrorMessage("File type supported.");
			}
			setReportFile(event.target.files[0]);
		} else {
			setErrorMessage("No file chosen.");
		}
	}

	async function onPageChanged(page: number) {
		setLoading(true);
		await paginateReports(page);
		getAllReports().finally(() => {
			setLoading(false);
		});
	}
	function createNewReport() {
		if (reportType === undefined || reportDescription === undefined || reportFile === undefined) {
			error("Invalid Report params");
			return;
		} else {
			setAdding(true);
			addReport(reportType, reportFile, reportDescription).finally(() => {
				getAllReports().finally(() => {
					setAdding(false);
					setModal(false);
					setErrorMessage("File format supported : pdf, excel, csv, jpg and png.");
					setIsFormatValid(false);
				});
			});
		}
	}
	useEffect(() => {
		setLoading(true);
		Promise.all([getAllReports()]).finally(() => {
			setLoading(false);
		});
	}, []);

	return (
		<div className="page-content container-fluid reports">
			{/* <div className='blurred-feature'>
                <div className={'under-construction'}>
                    <span>
                        <AlertTriangle size={24} color={'#f4a850'} />
                        Under Construction
                    </span>
                    <p>
                        We are hard at work to bring this feature to you and your team!
                    </p>
                    <img src={under_construction} height={100} width={100}></img>
                    <p>
                        While we work to make label generation the best it can be, please contact our team at<a> hello@journeyfoods.com </a> 
                        if you want personalized help with label creation for your products and ingredients.
                    </p>
                </div>
            </div> */}
			<div className="all-reports mt-4 pt-2">
				<div className="page-header">
					<h4 className="mb-4 font-weight-semibold">All Documents</h4>
				</div>

				{loading ? (
					<Loader className="fa-spin" />
				) : (
					<Table borderless>
						<thead>
							<tr>
								<th>Company Name</th>
								<th>Document Type</th>
								<th>Date Added</th>
								<th>Description</th>
								<th className="actions d-flex">Actions</th>
							</tr>
						</thead>
						<tbody>
							{(user_email == "opcito+compuser@journeyfoods.com" ? SAMPLE_REPORTS : [])
								.concat(reports)
								.map((report) => (
									<ReportRow key={report.id} report={report} />
								))}
						</tbody>
					</Table>
				)}
			</div>

			{(role === "JF_ADMIN" ||
				role === "COMPANY_ADMIN" ||
				role === "JF_STAFF" ||
				role === "COMPANY_USER") && (
				<span className="action-button-group">
					<Button className="btn-circle btn-adder btn-secondary" onClick={() => setModal(true)}>
						<Plus size={28} />
					</Button>
				</span>
			)}

			<Modal isOpen={modal}>
				<ModalHeader>
					<Upload className="color-bg-blue mr-2" size={24} />
					Upload New Report
					<X className="float-right" onClick={toggleAddForm} />
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup className="mb-3">
							<Label>Report Type</Label>
							<Input
								placeholder="Report Type"
								value={reportType}
								style={{ width: "100%", padding: "8px" }}
								onChange={(e) => setReportType(e.target.value)}
							/>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Upload Report</Label>
							<br />

							<InputGroup>
								<Input
									type="file"
									accept={REPORTS_ALLOWED_FILE_EXTENSIONS.join(", ")}
									onChange={selectReportFile}
									style={{ padding: "8px", borderStyle: "dashed", borderColor: "#cfdbea" }}
								/>
								<InputGroupAddon addonType="append">
									<Upload
										className="color-bg-blue"
										size={16}
										style={{ marginLeft: "-29px", marginTop: "15px", zIndex: 100 }}
									/>
								</InputGroupAddon>
							</InputGroup>
							<p style={{ color: isFormatValid ? "green" : "red" }}>*{errorMessage}</p>
						</FormGroup>

						<FormGroup className="mb-3">
							<Label>Description</Label>
							<Input
								type="textarea"
								rows={6}
								value={reportDescription}
								onChange={(e) => setReportDescription(e.target.value)}
								name="description"
								placeholder="Description"
								style={{ width: "100%", padding: "8px" }}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						className="color-white mr-2"
						type="submit"
						color="primary"
						onClick={createNewReport}
						disabled={!isFormatValid || adding || reportFile === undefined}
					>
						<FileText size={18} className="mr-2" />
						{adding ? "Adding" : "Add"} Report
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		reports: state.report.reports.list,
		pagination: state.report.reports.pagination,
		requests: state.report.requests,
		user_email: state.user.profile?.email, // for determing display of sample report temporarily
		role: state.user.profile?.role
	};
};

const mapDispatchToProps = {
	getAllReportRequest: () => reportService.getAllReportRequests(),
	getAllReports: () => reportService.getAllReports(),
	paginateReports: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateReports(page));
	},
	addReport: (reportType: string, reportFile: File, reportDescription: string) =>
		reportService.addReport(reportType, reportFile, reportDescription),
	addReportRequest: (reportType: string, reportDescription: string) =>
		reportService.addReportRequest(reportType, reportDescription)
};

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
