import React, { useEffect, useState } from "react";
import SuitabilityBar from "../../../components/suitabilityBar";
import { connect } from "react-redux";
import { FormGroup, Input, InputGroup, Table, Tooltip } from "reactstrap";
import {
	ArrowRight,
	ChevronDown,
	Sliders,
	Star,
	Info,
	Share2,
	Loader as LoaderIcon,
	X,
	Search,
	Check,
	Upload
} from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import Loader from "../../../components/loader";
import { IIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";
import ingredientImagePlaceholder from "../../../assets/images/olive-ingredient-icon.png";
import "../../../assets/scss/pages/dashboard/_dashlist.scss";
import ingredientService from "../../../services/ingredientService";
import config from "../../../utils/config";
import { routes } from "../../routes";
import Pagination from "../../../components/pagination";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import { ingredients } from "../../../redux/actions/actionContants";
// import { sampleIngredients } from "../exampleData/ingredientSample";
import ProgressGradient from "../../../components/common/progressGradient";
import { toCamelCase } from "../../../utils/string";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { ImageSkeleton, TableSkeleton } from "../../../utils/ghostUI";
import userService from "../../../services/userService";
import { IUser } from "../../../interfaces/user";
import notification from "../../../utils/notification";
import IngredientRow from "./ingredientRow";

interface IProps extends RouteComponentProps {
	ingredients: IPaginateList<IIngredient[]>;
	allIngredients?: boolean;
	fetchIngredients: () => Promise<any>;
	paginate: (page: number) => void;
	starIngredient: (id: string, isStarred: boolean) => Promise<any>;
}

const SupplierIngredientList: React.FC<IProps> = ({
	ingredients: { list, pagination },
	paginate,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		Promise.all([props.allIngredients ? props.fetchIngredients() : () => {}]).finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	return (
		<div className="dash-list" style={{ position: "relative" }}>
			{/* Display filters and pagination if on ingredients page showing all ingredients */}
			{props.allIngredients ? (
				<div style={{ paddingTop: 20 }}>
					<span className="title mb-2 d-flex">
						<h4 className="mr-2 font-weight-semibold">All Ingredients</h4>
						<h4 className="count color-bg-blue font-weight-normal">
							{loading ? <LoaderIcon className={"fa-spin"} /> : pagination.total}
						</h4>
					</span>
				</div>
			) : (
				<></>
			)}
			<Table borderless>
				<thead>
					<tr key="ingredient-list-header">
						<th></th>
						<th>Ingredient</th>
						<th>Usage</th>
						<th>Certifications</th>
						<th>Datasheet</th>
						<th>Price/kg (est.)</th>
					</tr>
				</thead>
				{loading ? (
					<TableSkeleton />
				) : (
					<tbody>
						{/* { (props.allIngredients ? list : props.starredIngredients).map((i: any, index: any) =>  ( */}
						{list.map((i: any, index: number) => (
							<IngredientRow data={i} index={index} isDetail={true} />
						))}
					</tbody>
				)}
			</Table>
			{pagination.pages > 1 && (
				<Pagination
					pages={pagination.pages}
					page={pagination.page}
					size={pagination.size}
					total={pagination.total}
					handlePageChange={paginate}
					// className="pagination-wrapper_remover"
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	starredIngredients: state.ingredient.starred,
	ingredients: state.ingredient.ingredients,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getStarredIngredients: () => ingredientService.getStarredIngredients(),
	fetchIngredients: () => ingredientService.getIngredients(),
	searchIngredient: (term: string) => ingredientService.searchIngredient(term),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(ingredients.PAGINATE_INGREDIENTS, page));
	},
	starIngredient: (id: string, isStarred: boolean) =>
		ingredientService.starIngredient(id, isStarred),
	getUserProfile: () => userService.getUserProfile()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierIngredientList));
