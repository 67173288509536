import { IPaginateList } from "../../interfaces/pagination";
import { supplier } from "../../redux/actions/actionContants";

export interface ISupplierState {
	result: any;
	ingredients: IPaginateList<any[]>;
}

const INIT_STATE: ISupplierState = {
	result: [],
	ingredients: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 20,
			total: 0
		}
	}
};

export default function (state: ISupplierState = INIT_STATE, action: any) {
	switch (action.type) {
		case supplier.GET_SUPPLIER_INGREDIENT:
			return {
				...state,
				ingredients: action.payload
			};
		case supplier.PAGINATE_SUPPLIER_INGREDIENT:
			return {
				...state,
				ingredients: {
					...state.ingredients,
					pagination: {
						...state.ingredients.pagination,
						page: action.payload
					}
				}
			};
		case supplier.GET_ALL_SUPPLIER_RESPONSE:
			return {
				...state,
				result: action.payload
			};

		default:
			return state;
	}
}
