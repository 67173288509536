import React, { useEffect, useState } from "react";
import Slider from "../../../components/slider";
import ProductItem from "./productItem";
import { connect } from "react-redux";
import Button from "../../../components/common/button";
import { PRODUCT_TYPE, IProduct } from "../../../interfaces/products";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { routes } from "../../routes";
import { ShoppingCart, Loader } from "react-feather";
import { IApplicationState } from "../../../redux/reducers";
import productService from "../../../services/productService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { PaginateSlider } from "../../../components/slider/paginateSlider";
import { IUser } from "../../../interfaces/user";
import Upgrade from "../../../components/common/upgrade";
import { IBrand } from "../../../interfaces/company";
import { IPaginateList } from "../../../interfaces/pagination";

interface IProps extends RouteComponentProps {
	history: History;
	active: IPaginateList<IProduct[]>;
	getActiveProducts: () => Promise<any>;
	removeProduct: (productId: string) => Promise<any>;
	selected_brand: IBrand;
	profile?: IUser | undefined;
}

const ActiveProduct: React.FC<IProps> = ({
	history,
	getActiveProducts,
	active,
	removeProduct,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [removing, setRemoving] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		getActiveProducts().finally(() => setLoading(false));
	}, [props.selected_brand]);

	function title() {
		return (
			<div className="title">
				<h4 className="mr-2 font-weight-semibold">Active Products</h4>
				{loading ? (
					<Loader className="fa-spin mr-2" />
				) : (
					<h4 className="count color-bg-blue font-weight-normal mr-2">{active.pagination.total}</h4>
				)}
				<Button color="primary" className="btn-round">
					<ShoppingCart className="mr-2" size={18} />
					Retail
				</Button>
			</div>
		);
	}

	function onRemoveHandler(productId: string) {
		setRemoving(true);

		confirmAlert({
			title: "Remove Product",
			message: "Are you sure you want to remove this product?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						removeProduct(productId).finally(() => {
							setLoading(true);
							getActiveProducts().finally(() => {
								setLoading(false);
							});
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	// return null;
	return (
		<div className="active-product">
			<Slider
				itemPerPage={4}
				loading={loading}
				items={active.list}
				render={(product: IProduct) => (
					<ProductItem
						product={product}
						productType={PRODUCT_TYPE.ACTIVE}
						onRemoveHandler={onRemoveHandler}
					/>
				)}
				title={title}
				control={true}
				access={props.profile?.company?.subscription_type}
			/>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	active: state.product.active,
	profile: state.user.profile,
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getActiveProducts: () => productService.getActiveProducts(),
	removeProduct: (productId: string) => productService.removeProduct(productId)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActiveProduct));
