import React, { useEffect, useState } from "react";
import { Loader, PlusCircle, Upload, User } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SideForm from "../../../components/sideForm";
import { IApplicationState } from "../../../redux/reducers";
import { routes } from "../../routes";

import ReactImageFallback from "react-image-fallback";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { toCamelCase } from "../../../utils/string";
import ingredientService from "../../../services/ingredientService";
import { IIngredient } from "../../../interfaces/ingredient";
import PreviewProduct from "../../products/productDetail/components/previewProduct";
import { IPaginateList } from "../../../interfaces/pagination";
import supplierService from "../../../services/supplierService";

interface IProps extends RouteComponentProps {
	history: any;
	allSupplier: any;
	getAllSupplierByUser: () => Promise<any>;
}

const Supplier: React.FC<IProps> = ({ history, getAllSupplierByUser, allSupplier }) => {
	const [isLoading, setIsLoading] = useState<boolean>();

	useEffect(() => {
		try {
			setIsLoading(true);
			getAllSupplierByUser().finally(() => {
				setIsLoading(false);
			});
		} catch {
			setIsLoading(false);
		}
	}, []);

	return (
		<div>
			<h3 className="mr-4 font-weight-semibold">Supplier List</h3>
			{isLoading ? (
				<Loader className="fa-spin" />
			) : (
				<div style={{ flexWrap: "wrap", gap: "1rem" }} className="mt-4 d-flex">
					{allSupplier.map((supplier: any) => (
						<div
							style={{ width: "200px", height: "200px", cursor: "pointer" }}
							className="d-flex flex-column align-items-center "
							onClick={() => history.push(`${routes.SUPPLIER}/${supplier.id}`)}
							key={supplier.id}
						>
							<div
								id="account-joyride"
								className="rounded-circle d-flex justify-content-center align-items-center mb-2"
								style={{
									background:
										"linear-gradient(42.3deg, rgba(57, 94, 250, 0.75) -0.63%, rgba(23, 201, 220, 0.75) 55.93%, rgba(29, 214, 174, 0.75) 117.41%)",
									width: 90,
									height: 90,
									boxShadow: "0px 0px 20px rgba(4, 25, 40, 0.05)"
								}}
							>
								<User size={40} color={"#fff"} />
							</div>
							<h5 style={{ textAlign: "center", wordBreak: "break-all" }}>{supplier.name}</h5>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	allSupplier: state.supplier.result
});

const mapDispatchToProps = {
	getAllSupplierByUser: () => supplierService.getAllSupplierByUser()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Supplier));
