import React, { useEffect, useState } from "react";
import {
	Label,
	Row,
	Col,
	ListGroup,
	Input,
	ListGroupItem,
	Table,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from "reactstrap";
import Select from "react-select";
import { Box, ChevronRight, Share, Share2, Trash2, User, Zap, ZapOff } from "react-feather";
import Button from "../../../../components/common/button";
import Loader from "../../../../components/loader";
import { IApplicationState } from "../../../../redux/reducers";
import userService from "../../../../services/userService";
import { connect } from "react-redux";
import CustomSelect from "../../../../components/common/customSelect";
import companyService from "../../../../services/companyService";
import { IUser } from "../../../../interfaces/user";
import { product } from "../../../../redux/actions/actionContants";

import { confirmAlert } from "react-confirm-alert";
import ReactImageFallback from "react-image-fallback";
import config from "../../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import { IPaginateList } from "../../../../interfaces/pagination";
import { IProduct } from "../../../../interfaces/products";
import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import productService from "../../../../services/productService";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import Pagination from "../../../../components/pagination";
import { TableSkeleton } from "../../../../utils/ghostUI";
import CompanyValidateModal from "./companyValidateModal";
import { info } from "../../../../utils/notification";
import { History } from "history";
import { routes } from "../../../routes";
import shareProduct from "./shareProduct";
import { SelectField } from "../../../../components/form/Select";

interface IProps {
	active: IPaginateList<IProduct[]>;
	getActiveProducts: () => Promise<any>;
	paginateActiveProducts: (page: number) => void;
	shareProductWithTeam: (id: string) => Promise<any>;
	unShareProductWithTeam: (id: string) => Promise<any>;
	unShareProductWithCompany: (id: string, companies: []) => Promise<any>;
	isJFAdmin: any;
	company: any;
	history: History;
}

interface ICompany {
	name: string;
	id: string;
}

const ProductList: React.FC<IProps> = ({
	active: { list, pagination },
	paginateActiveProducts,
	history,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>(false);
	const [companyModal, setCompanyModal] = useState<boolean>(false);
	const [unshareWithAll, setunshareWithAll] = useState<boolean>(false);

	const [productId, setProductId] = useState<string>("false");
	const [prefs, setPrefs] = useState<any>([]);
	const [company, setCompany] = useState<any>([]);

	useEffect(() => {
		try {
			setLoading(true);
			if (pagination?.page) {
				props.getActiveProducts().finally(() => {
					setLoading(false);
				});
			}
		} catch {
			setLoading(false);
		}
	}, [pagination?.page]);

	const onShareProductCompanyHandler = (id: string) => {
		confirmAlert({
			title: "Share Product with Company",
			message: "Are you sure you want to share this product with company ? ",
			buttons: [
				{
					label: "Share",
					onClick: () => {
						setProductId(id);
						setModal(true);
					}
				},
				{
					label: "Cancel",
					onClick: () => setModal(false)
				}
			]
		});
	};

	const onUnShareProductCompanyHandler = (id: string, companies: []) => {
		confirmAlert({
			title: "Unshare Product with Company/Team",
			message: "Are you sure you want to unshare this product ? ",
			buttons: [
				{
					label: "Team",
					onClick: () => {
						setModal(true);
						props.unShareProductWithTeam(id).finally(() => {});
					}
				},
				{
					label: "Company",
					onClick: () => {
						setProductId(id);
						setModal(false);
						setCompany(companies);
						setCompanyModal(true);
					}
				},
				{
					label: "Cancel",
					onClick: () => setModal(false)
				}
			]
		});
	};

	const onShareProductTeamHandler = (id: string) => {
		confirmAlert({
			title: "Share Product with Team Member",
			message: "Are you sure you want to share this product with member ? ",
			buttons: [
				{
					label: "Share",
					onClick: () => {
						props.shareProductWithTeam(id).then((res: any) => {
							info(res?.data?.message);
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => setModal(false)
				}
			]
		});
	};

	const unShareProductCompanyHandler = () => {
		let companyPayload: any = [];
		prefs?.map((company: ICompany) => companyPayload.push({ id: company.id }));
		props.unShareProductWithCompany(productId, companyPayload);
		setPrefs([]);
		setunshareWithAll(false);
		setCompanyModal(false);
	};

	useEffect(() => {
		if (unshareWithAll) {
			setPrefs([]);
		}
	}, [unshareWithAll]);

	const onSelectPref = (pref: ICompany) => {
		setunshareWithAll(false);
		const index = prefs?.findIndex((o: ICompany) => o.id === pref.id);
		let temp = [...prefs];
		if (index > -1) {
			temp.splice(index, 1);
		} else {
			temp = [...prefs, pref];
		}
		setPrefs(temp);
	};

	return (
		<div style={{ position: "relative" }}>
			<Table borderless>
				<thead>
					<tr style={{ textAlign: "center", backgroundColor: "#e1ecf3" }}>
						<th>Image</th>
						<th>Product Name</th>
						<th>Recommendation Count</th>
						{props.isJFAdmin && <th>Company</th>}
						<th>Share with Team</th>
						<th>Share with Company</th>
						<th>Unshare</th>
					</tr>
				</thead>
				{loading ? (
					<TableSkeleton />
				) : (
					<tbody>
						{list.map((product: any) => {
							return (
								<tr
									key={product.id}
									style={{ borderBottom: "1px solid #cedbe9" }}
									onClick={(e) => {
										e.stopPropagation();
										history.push(`${routes.PRODUCTS}/${product?.id}`);
									}}
								>
									<td style={{ paddingTop: 25, textAlign: "center" }}>
										{product?.image_uri !== "" ? (
											<ReactImageFallback
												style={{ borderRadius: 6, height: "30px", width: "30px" }}
												src={product.image_uri}
												fallbackImage={getIngredientImagePlaceholder()}
												alt="Product Image"
												loading="lazy"
											/>
										) : (
											<ReactImageFallback
												style={{ borderRadius: 6, height: "30px", width: "30px" }}
												src={config.api.endpoints.ingredients.ingredientImage(product.name)}
												fallbackImage={getIngredientImagePlaceholder()}
												alt="Ingredient Image"
											/>
										)}
									</td>
									<td style={{ paddingTop: 25, textAlign: "center", maxWidth: "26rem" }}>
										{product.name}
									</td>
									<td style={{ paddingTop: 25, textAlign: "center" }}>
										{product.recommendations_count}
									</td>
									{props.isJFAdmin && (
										<td style={{ paddingTop: 25, textAlign: "center" }}>{product.company.name}</td>
									)}
									<td style={{ textAlign: "center" }}>
										<Button
											className="btn-success text-white"
											onClick={(e) => {
												e.stopPropagation();
												onShareProductTeamHandler(product.master_product_id);
											}}
											disabled={product.is_shared_with_team}
										>
											<Share size={14} />
										</Button>
									</td>
									<td style={{ textAlign: "center" }}>
										<Button
											className="btn-warning text-white"
											onClick={(e) => {
												e.stopPropagation();
												onShareProductCompanyHandler(product.master_product_id);
											}}
										>
											<Share2 size={14} />
										</Button>
									</td>
									<td style={{ textAlign: "center" }}>
										{product?.is_shared_with_team || product?.shared_with_companies.length > 0 ? (
											<Button
												className="btn-danger text-white"
												onClick={(e) => {
													e.stopPropagation();
													onUnShareProductCompanyHandler(
														product.master_product_id,
														product.shared_with_companies
													);
												}}
											>
												<ZapOff size={14} />
											</Button>
										) : (
											<Button
												style={{ backgroundColor: "#7f868d", borderColor: "#7f868d" }}
												className="text-white"
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<Zap size={14} />
											</Button>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				)}
			</Table>
			{pagination.pages > 1 && (
				<Pagination
					pages={pagination.pages}
					page={pagination.page}
					size={pagination.size}
					total={pagination.total}
					handlePageChange={paginateActiveProducts}
				/>
			)}
			{modal && (
				<CompanyValidateModal
					isOpen={modal}
					toggle={() => setModal(!modal)}
					productId={productId}
					companyName={props.company}
				/>
			)}
			{companyModal && (
				<Modal isOpen={companyModal} className="modal-dialog share-product-modal">
					<ModalHeader
						toggle={() => {
							setunshareWithAll(false);
							setCompanyModal(!companyModal);
							setPrefs([]);
						}}
					>
						Unshare Product with Company
					</ModalHeader>
					<ModalBody className="px-3 py-1">
						{company.map((s: ICompany) => (
							<>
								{(prefs || []).findIndex((p: any) => p.id === s.id) > -1 ? (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 5,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											backgroundColor: "#3f65f1",
											border: "2px solid #3f65f1",
											cursor: "pointer",
											color: "#fff"
										}}
										onClick={() => onSelectPref(s)}
									>
										<Box color="#fff" /> {s.name}
									</div>
								) : (
									<div
										style={{
											display: "inline-block",
											alignSelf: "center",
											padding: 5,
											borderRadius: 8,
											marginRight: 6,
											marginBottom: 6,
											border: "2px solid #cedbe9",
											cursor: "pointer"
										}}
										onClick={() => onSelectPref(s)}
									>
										<Box color="#3f65f1" /> {s.name}
									</div>
								)}
							</>
						))}
						<Label
							check
							className="d-flex flex-row m-3 align-items-center"
							onChange={() => setunshareWithAll(!unshareWithAll)}
							style={{ cursor: "pointer", width: "fit-content" }}
						>
							<Input
								style={{ width: "1.3rem", height: "1.3rem" }}
								type="checkbox"
								checked={unshareWithAll}
								disabled={loading}
							/>
							<span className="ml-2" style={{ marginTop: 1, fontWeight: "bold" }}>
								Unshare with All Companies
							</span>
						</Label>
					</ModalBody>

					<ModalFooter>
						<Button
							disabled={prefs.length == 0 && !unshareWithAll}
							color="danger"
							onClick={unShareProductCompanyHandler}
						>
							Unshare
						</Button>
						<Button
							color="primary"
							onClick={() => {
								setPrefs([]);
								setunshareWithAll(false);
								setCompanyModal(!companyModal);
							}}
						>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company: state.user?.profile?.company.name,
	active: state.product.active,
	isJFAdmin: state.user?.profile?.role === "JF_ADMIN"
});

const mapDispatchToProps = {
	getActiveProducts: () => productService.getActiveProducts(),
	paginateActiveProducts: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(product.PAGINATE_ACTIVE_PRODUCT, page));
	},
	getUserProfile: () => userService.getUserProfile(),
	getCompanyTypes: () => companyService.getCompanyTypes(),
	shareProductWithTeam: (id: string) => productService.shareProductWithTeam(id),
	unShareProductWithTeam: (id: string) => productService.unShareProductWithTeam(id),
	unShareProductWithCompany: (id: string, companies: []) =>
		productService.unShareProductWithCompany(id, companies)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
