import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { routes } from "../routes";
import PermissionRoute from "../../components/hoc/PermissionRoute";
import UserRequest from "./userRequests";
import { USER_ROLES } from "../../interfaces/roles";
import Tabs from "../../components/tab";
import ProductsTable from "./productsTable";
import ReportsTable from "./reportsTable";
import EmailSchedule from "./EmailSchedule";
import CompaniesTable from "./CompaniesTable";
import IntegrationsTable from "./integrationsTable";
import UploadsTable from "./uploadTable";
import { route } from "../../redux/actions/actionContants";
import AdminBilling from "./billingTable";
import AdminRecommendations from "./adminRecommendations/adminRecommendations";
import Email from "./Email";
import AdminIngredients from "./adminIngredients/adminIngredients";
import shareProduct from "../account/company/components/shareProduct";

const AdminRouter: React.FC = () => (
	<>
		<Tabs
			tabs={[
				{
					name: "Users",
					route: routes.PENDING,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Products",
					route: routes.ADMIN_PRODUCTS,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Reports",
					route: routes.ADMIN_REPORTS,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Email Schedule",
					route: routes.ADMIN_EMAIL_SCHEDULE,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Email",
					route: routes.ADMIN_EMAIL,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Integrations",
					route: routes.ADMIN_INTEGRATIONS
				},
				{
					name: "Companies",
					route: routes.ADMIN_COMPANIES
				},
				{
					name: "Upload Requests",
					route: routes.ADMIN_UPLOADS
				},
				{
					name: "Billing",
					route: routes.ADMIN_BILLING
				},
				{
					name: "Recommendations",
					route: routes.ADMIN_RECOMMENDATIONS
				},
				{
					name: "Share Product",
					route: routes.ADMIN_SHARE_PRODUCTS,
					tabRoles: [USER_ROLES.ADMIN]
				},
				{
					name: "Ingredients",
					route: routes.ADMIN_INGREDIENTS,
					tabRoles: [USER_ROLES.ADMIN]
				}
			]}
		/>
		<Switch>
			<PermissionRoute
				path={routes.PENDING}
				component={UserRequest}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_PRODUCTS}
				component={ProductsTable}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_REPORTS}
				component={ReportsTable}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_EMAIL_SCHEDULE}
				component={EmailSchedule}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_EMAIL}
				component={Email}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_INTEGRATIONS}
				component={IntegrationsTable}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_COMPANIES}
				component={CompaniesTable}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_UPLOADS}
				component={UploadsTable}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_BILLING}
				component={AdminBilling}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_RECOMMENDATIONS}
				component={AdminRecommendations}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_INGREDIENTS}
				component={AdminIngredients}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<PermissionRoute
				path={routes.ADMIN_SHARE_PRODUCTS}
				component={shareProduct}
				componentRoles={[USER_ROLES.ADMIN]}
			/>
			<Redirect to={routes.PENDING} from="/" />
		</Switch>
	</>
);

export default AdminRouter;
