import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import {
	AlertCircle,
	Check,
	CheckSquare,
	FileText,
	Loader,
	UploadCloud,
	X,
	XCircle
} from "react-feather";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { IApplicationState } from "../../../../../redux/reducers";
import ingredientService from "../../../../../services/ingredientService";
import { IOption } from "../../../../../components/form/Select";
import { ICountry } from "../../../../../interfaces/country";
import CustomSelect from "../../../../../components/common/customSelect";
import { ErrorMessage, FormikErrors, FormikValues } from "formik";

interface IProps {
	fileSingle?: string;
	title?: string;
	country_id?: string;
	countries: IOption[];
	ingredient_id: string | undefined;
	showTitle: boolean;
	editing?: boolean;
	fileType?: string;
	uploadIngredientInfoFile: (ingredient_id: string, file: File) => Promise<any>;
	onChange: (...args: any[]) => void;
	fileTypes?: string[];
	name: string;
	errors?: FormikErrors<FormikValues>;
	id: string;
}

const CountryResourceUpload: React.FC<IProps> = ({
	title,
	fileSingle,
	ingredient_id,
	country_id,
	countries,
	uploadIngredientInfoFile,
	onChange,
	name,
	showTitle,
	fileTypes,
	fileType,
	errors,
	id,
	...props
}) => {
	const [uploadedFile, setUploadedFile] = useState<File>();
	const [uploading, setUploading] = useState<boolean>();
	const [fileUploadError, setFileUploadError] = useState<boolean>();
	const [fileUrl, setFileUrl] = useState<string | undefined>(fileSingle);
	const [country, setCountry] = useState<{ label: string; value: ICountry }>();
	const [fileName, setFileName] = useState<string | undefined>(fileType);

	useEffect(() => {
		if (country_id && countries) {
			const countryOption = countries.find((o) => o.value.id == country_id);
			setCountry(countryOption);
		} else if (name == "upcycled") {
			// Temporary handling for upcycled link
			setCountry(countries.find((o) => o.value.alpha_3 == "USA"));
		}
	}, [country_id, countries]);

	useEffect(() => {
		setFileUrl(fileSingle);
	}, [fileSingle]);

	const onCountryChange = (option: any) => {
		setCountry(option);
	};

	const onFileNameChange = (option: { label: string; value: string }) => {
		const name = option.value;
		if (!name) {
			setFileName(undefined);
			return;
		}
		setFileName(name);
	};

	const onFileSelect = (acceptedFiles: File[]) => {
		setFileUploadError(false);
		setUploadedFile(acceptedFiles[0]);
	};

	const clearFileSelection = (e: any) => {
		e.preventDefault();
		setUploadedFile(undefined);
		setUploading(false);
	};

	const isFormComplete = () => {
		return (
			ingredient_id != undefined &&
			uploadedFile != undefined &&
			country != undefined &&
			(fileName != undefined || !fileTypes)
		);
	};

	const updateFormField = (fileUrl: string, countryId: string, fileName: string = "") => {
		let data: { [index: string]: any } = { country: countryId };
		const getField = () => {
			switch (name) {
				case "datasheets":
					return "datasheet";
				default:
					return "link";
			}
		};
		const linkFieldName = getField();
		data[linkFieldName] = fileUrl;
		if (["certifications", "allergens"].includes(name)) data["name"] = fileName;
		onChange(name, data);
	};

	const onConfirmUpload = (e: any) => {
		e.preventDefault();
		if (ingredient_id && uploadedFile && country && (fileName || !fileTypes)) {
			setUploading(true);
			uploadIngredientInfoFile(ingredient_id, uploadedFile)
				.then((res: any) => {
					if (res.fileUrl) {
						setFileUrl(res.fileUrl);
						updateFormField(res.fileUrl, country.value.id, fileName);
					}
				})
				.catch((error) => {
					setFileUploadError(true);
					setUploadedFile(undefined);
					setUploading(false);
				})
				.finally(() => {
					setFileUrl(undefined);
					setCountry(undefined);
					setFileName(undefined);
					setUploading(false);
					setUploadedFile(undefined);
				});
		}
	};

	const handleFileRemove = (e: any) => {
		e.preventDefault();
		if (!fileUrl || !country) return;
		let data: { [index: string]: any } = { country: country.value.id, name: fileName };

		const getField = () => {
			switch (name) {
				case "datasheets":
					return "datasheet";
				default:
					return "link";
			}
		};
		const fieldName = getField();
		data[fieldName] = ""; //Unsetting file url for given country
		onChange(name, data);
	};

	const isFieldEditDisabled = () => {
		return (
			uploading ||
			(fileUrl != undefined && country != undefined && (fileName != undefined || !fileTypes))
		);
	};

	return (
		<Container id={name} className={"resource-upload-section"}>
			<Col>
				{showTitle && (
					<Row className={"d-flex align-items-center mb-2"}>
						<h5>{title}</h5>
						{fileUrl ? <CheckSquare color={"#0ed7ac"} /> : <AlertCircle color={"#e85a73"} />}
					</Row>
				)}
				{
					<ErrorMessage
						name={name}
						render={(error) => <small className="text-danger">{error}</small>}
					/>
				}
				<Row className="country-file-upload">
					<Col xs={"4"} md={"4"} lg={"4"}>
						<Row>
							<span>Country:</span>
						</Row>
						<Row>
							<CustomSelect
								isDisabled={isFieldEditDisabled()}
								key={country?.label}
								width={200}
								onChange={onCountryChange}
								placeholder={"Country"}
								options={countries}
								currentValue={country}
							/>
						</Row>
					</Col>
					{fileTypes && (
						<Col xs={"4"} md={"4"} lg={"4"}>
							<Row>
								<span>Name:</span>
							</Row>
							<Row>
								<CustomSelect
									isDisabled={isFieldEditDisabled()}
									key={fileName}
									width={200}
									onChange={onFileNameChange}
									placeholder={"File Type"}
									options={fileTypes.map((name: string) => ({
										label: name,
										value: name
									}))}
									currentValue={fileName ? { label: fileName, value: fileName } : undefined}
								/>
							</Row>
						</Col>
					)}
					<Col xs={"4"} md={"4"} lg={"4"}>
						<Row>
							<span>Current File:</span>
						</Row>
						<Row className={"document"}>
							<div className={"file-name"}>
								{fileUrl ? (
									<>
										<FileText color={"#3f65f1"} className={"mr-2"} />
										<a style={{ color: "#3f65f1" }} href={fileUrl} target="_blank">
											{title}
										</a>
									</>
								) : uploadedFile ? (
									<>
										<FileText color={"#3f65f1"} className={"mr-2"} />
										<span style={{ color: "#3f65f1" }}>{uploadedFile.name}</span>
									</>
								) : (
									<span style={{ color: "#e85a73" }}>
										{fileUploadError ? "File Error" : "No File Found"}
									</span>
								)}
							</div>
							{uploadedFile ? (
								<div className={"confirm-selection"}>
									<Button
										className={"cancel"}
										disabled={uploading || !isFormComplete()}
										onClick={clearFileSelection}
									>
										<span>Clear Selection</span>
										<X color={"#fff"} size={20} />
									</Button>
									<Button
										className={"confirm"}
										disabled={uploading || !isFormComplete() || isFieldEditDisabled()}
										onClick={onConfirmUpload}
									>
										{uploading ? (
											<>
												<span>Uploading...</span>
												<Loader color={"#fff"} size={20} className="fa-spin" />
											</>
										) : (
											<>
												<span>Upload</span>
												<Check color={"#fff"} size={20} />
											</>
										)}
									</Button>
								</div>
							) : props.editing ? (
								<section
									className={`remove ${!fileUrl ? "disabled" : ""}`}
									onClick={handleFileRemove}
								>
									<div>
										<XCircle size={20} />
									</div>
								</section>
							) : (
								<Dropzone disabled={isFieldEditDisabled()} onDrop={onFileSelect}>
									{({ getRootProps, getInputProps }) => (
										<section
											className={`resource-upload ${isFieldEditDisabled() ? "disabled" : ""}`}
										>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<UploadCloud size={20} />
											</div>
										</section>
									)}
								</Dropzone>
							)}
						</Row>
					</Col>
				</Row>
			</Col>
		</Container>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	ingredient_id: state?.ingredient?.selected?.id
});

const mapDispatchToProps = {
	uploadIngredientInfoFile: (ingredient_id: string, file: File) =>
		ingredientService.uploadIngredientInfoFile(ingredient_id, file)
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryResourceUpload);
