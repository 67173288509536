import React from "react";
import Button from "../../../components/common/button";
import { FileText, Download } from "react-feather";
import moment from "moment";
import { IReport } from "../../../interfaces/report";
import { Link } from "react-router-dom";

type ReportStatus = "complete" | "processsing" | "submitted";

interface IProps {
	report: IReport | any;
}

const ReportRow: React.FC<IProps> = ({ report }) => (
	<tr>
		<td className="w-20">{report.requested_by.name || "Company Name"}</td>
		<td className="w-20">{report.report_type || "Report Type"}</td>
		<td className="w-20">{moment(report.request_date).format("MMM DD YYYY HH:mm a")}</td>
		<td className="w-40">{report.description || "Description"}</td>
		<td className="actions d-flex justify-content-end">
			<span style={{ marginBottom: "0.5rem" }} className={report.status.toLowerCase()}>
				<FileText size={17} className="mr-2" />
				{report.status}
			</span>

			{report.status === "UPLOADED" ? (
				<Link to={`${report.report_uri}`} target="_blank">
					<Button outline color="primary">
						<Download size={17} className="mr-2" />
						Download
					</Button>
				</Link>
			) : (
				<div></div>
			)}
		</td>
	</tr>
);

export default ReportRow;
