import React from "react";
import { connect } from "react-redux";
import { Check, Upload } from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../../assets/scss/pages/dashboard/_dashlist.scss";
import config from "../../../utils/config";
import { routes } from "../../routes";
import { toCamelCase } from "../../../utils/string";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { ImageSkeleton } from "../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	data: any;
	index: number;
	history: any;
	isDetail?: boolean;
}

const IngredientRow: React.FC<IProps> = ({ data, index, history, isDetail }) => {
	const hasDatasheet = data?.country_data.filter((item: any) => item.datasheet !== null);
	const hasCertification = data?.country_data.filter((item: any) => item.certification?.length);
	return (
		<tr
			onClick={() => history.push(`${routes.INGREDIENTS}/${data.id}`)}
			className="list-row"
			key={data.id + index}
		>
			<td>
				<ReactImageFallback
					initialImage={<ImageSkeleton className={"table-img-ghost"} />}
					src={config.api.endpoints.ingredients.ingredientImage(data.jf_display_name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Ingredient Image"
					className="mr-2 ml-2"
					style={{ borderRadius: 6 }}
				/>
			</td>
			<td>{toCamelCase(data.name)}</td>
			{isDetail && (
				<td>
					{data.usage && data.usage.active === 0 && data.usage.concept === 0 ? (
						"No data"
					) : (
						<>
							{data.usage && data.usage.active > 0 && (
								<p className="m-0">{data.usage.active} Active</p>
							)}
							{data.usage && data.usage.concept > 0 && (
								<p className="m-0">{data.usage.concept} Concept</p>
							)}
						</>
					)}
				</td>
			)}
			<td style={{ textAlign: "center" }}>
				{hasCertification.length ? <Check></Check> : <Upload size={17} />}
			</td>
			<td style={{ textAlign: "center" }}>
				{hasDatasheet.length ? <Check></Check> : <Upload size={17} />}
			</td>
			<td>
				{data.price || data.price_per_unit
					? `$${Number(data.price)?.toFixed(2) || Number(data.price_per_unit)?.toFixed()}`
					: "No data"}
			</td>
		</tr>
	);
};

export default connect(null, null)(withRouter(IngredientRow));
