import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
import SupplierDashboard from "./components/supplierDashboard";

const DashboardRouter: React.FC = () => (
	<Switch>
		<Route path={routes.DASHBOARD} component={SupplierDashboard} />
	</Switch>
);

export default DashboardRouter;
