import React, { useState, useEffect, useRef } from "react";
import { ICompany, IBrand } from "../../../../interfaces/company";
import {
	IProductIngredient,
	IManufacturer,
	IProductType,
	IProductPackage
} from "../../../../interfaces/products";
import { InjectedFormikProps, FieldArray, ErrorMessage } from "formik";
import Input from "../../../../components/form/Input";
import { ISearchIngredient } from "../../../../interfaces/ingredient";
import {
	Row,
	Col,
	Input as ReactInput,
	FormGroup,
	Label,
	ListGroup,
	ListGroupItem,
	InputGroup,
	InputGroupAddon,
	Button
} from "reactstrap";
import { X, Loader } from "react-feather";
import IngredientItem from "../../../ingredients/components/ingredientItem";
import { useDebounce } from "../../../../utils/hooks";
import { connect } from "react-redux";
import ingredientService from "../../../../services/ingredientService";
import { SelectField } from "../../../../components/form/Select";
import userService from "../../../../services/userService";
import productTypeService from "../../../../services/productTypeService";
import { IApplicationState } from "../../../../redux/reducers";
import config from "../../../../utils/config";
import { getCostMarkup } from "../../../../utils/product";
import searchService from "../../../../services/searchService";
import { Dispatch } from "redux";
import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import { search } from "../../../../redux/actions/actionContants";
import { IPaginateList } from "../../../../interfaces/pagination";
import PackageSearch from "./packageSelect/packageSearch";
import PackageItemPlaceholder from "../../../..//assets/images/product-packaging-placeholder.png";
import { ValueType } from "react-select";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import { uniqBy } from "../../../../utils/array";
import commonService from "../../../../services/commonService";

export interface IProductFormElements {
	name: string;
	manufacturer: { label: string; value: string | null } | null;
	product_type: {
		label: string;
		value: { category: string; title: string; id: string } | null; // Updated product type format for new product type structure
	} | null;
	// product_type: { label: string, value: string | null } | null; // OLD FORMAT
	created_date: string;
	brand: { label: string; value: string } | null;
	fulfillment_date: string;
	serving_size: number;
	cost_margin: number;
	cost: number;
	markup: number;
	notes: string;
	country: { label: string; value: string } | null;
	currency: { label: string; value: string } | null;
	product_status: { label: string; value: boolean };
	company: {
		label: string;
		value: ICompany;
	} | null;
	flavor: string;
	code: string;
	ingredients: IProductIngredient[];
	packaging?: IProductPackage;
	objective?: { label: string; value: string }[] | null;
	top_personas?: [];
	unit_serving_size?: string;
	buttonType?: string; // Add buttonType as an optional field
	// actionType: string | null;
}

interface IProductFormProps {
	isAdmin?: boolean;
	companies: {
		label: string;
		value: ICompany;
	}[];
	searchResults: IPaginateList<any[]>;
	portfolioSearchResults: ISearchIngredient[];
	searchIngredient: (name: string) => Promise<any>;
	getBrands: () => Promise<any>;
	getManufacturers: () => Promise<any>;
	getProductTypes: () => Promise<any>;
	getCurrencies: () => Promise<any>;
	paginate: (page: number) => void;
	getSearchResults: (term: string) => Promise<any>;
	searchPortfolioIngredients: (term?: string) => Promise<any>;
}

const ProductFormElements: React.FC<
	InjectedFormikProps<IProductFormProps, IProductFormElements>
> = ({
	searchResults: { list, pagination },
	portfolioSearchResults,
	searchPortfolioIngredients,
	...props
}) => {
		const {
			values: {
				name,
				cost_margin,
				cost,
				markup,
				flavor,
				created_date,
				fulfillment_date,
				manufacturer,
				product_type,
				serving_size,
				notes,
				product_status,
				code,
				ingredients,
				brand,
				company,
				country,
				currency,
				packaging,
				objective,
				unit_serving_size
			},
			isAdmin,
			companies,
			handleChange,
			handleBlur,
			touched,
			errors,
			searchIngredient,
			getBrands,
			getManufacturers,
			getProductTypes,
			setFieldValue,
			setFieldTouched,
			getCurrencies,
			paginate,
			getSearchResults,
			setErrors
		} = props;

		const [searchTerm, setSearchTerm] = useState<string>("");
		const prevSearchRef = useRef<string | undefined>();
		const [isSearchCompleted, setSearchCompleted] = useState<boolean>(false);
		const [searchLoading, setSearchLoading] = useState<boolean>(false);
		const [searchIngredients, setSearchIngredients] = useState<ISearchIngredient[]>([]);
		const [portfolioIngredients, setPortfolioIngredients] = useState<ISearchIngredient[]>([]);
		const [brands, setBrand] = useState<{ label: string; value: string }[]>([]);
		const [manufacturers, setManufacturers] = useState<{ label: string; value: string | null }[]>([]);
		const [countries, setCountries] = useState<{ label: string; value: string | null }[]>([]);
		const [currencies, setCurrencies] = useState<{ label: string; value: string | null }[]>([]);
		const [brandLoading, setBrandLoading] = useState<boolean>(false);
		const [countryLoading, setCountryLoading] = useState<boolean>(false);
		const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
		const [manufacturerLoading, setManufacturerLoading] = useState<boolean>(false);

		const [product_types, setProductTypes] = useState<
			{ label: string; value: { category: string; title: string } | null }[]
		>([]);
		const [selectedPackage, setSelectedPackage] = useState<IProductPackage>();
		const [productTypesLoading, setProductTypesLoading] = useState<boolean>(false);

		function handleSearchChange(e: any) {
			const search = e.target.value;
			setSearchCompleted(false);
			setSearchTerm(search);
		}

		const debouncedSearchTerm = useDebounce(searchTerm, 500);

		useEffect(() => {
			if (debouncedSearchTerm) {
				// Reset pagination and results on search change
				if (prevSearchRef.current !== debouncedSearchTerm) {
					paginate(1);
					setSearchIngredients([]);
				}
				setSearchLoading(true);
				setSearchCompleted(false);
				getSearchResults(debouncedSearchTerm);
			} else {
				setSearchLoading(false);
				setSearchIngredients([]);
			}
			prevSearchRef.current = debouncedSearchTerm;
		}, [debouncedSearchTerm, pagination.page]);

		useEffect(() => {
			if (debouncedSearchTerm) {
				setSearchLoading(true);
				searchPortfolioIngredients(debouncedSearchTerm).finally(() => {
					setSearchLoading(false);
				});
			}
		}, [debouncedSearchTerm]);

		// Concat ingredient results on pagination update
		useEffect(() => {
			if (debouncedSearchTerm) {
				setSearchIngredients(searchIngredients.concat(list));
				setSearchLoading(false);
				setSearchCompleted(true);
			}
		}, [list]);

		useEffect(() => {
			setPortfolioIngredients(portfolioSearchResults);
		}, [portfolioSearchResults]);

		useEffect(() => {
			setBrandLoading(true);
			getBrands()
				.then((brands: IBrand[]) => {
					setBrand(brands.map((brand) => ({ label: brand.name || "", value: brand.id })));
				})
				.finally(() => {
					setBrandLoading(false);
				});

			setManufacturerLoading(true);
			getManufacturers()
				.then((manufacturers: IManufacturer[]) => {
					setManufacturers(
						manufacturers.map((manufacturer) => ({
							label: manufacturer.name || "",
							value: manufacturer.id
						}))
					);
				})
				.finally(() => {
					setManufacturerLoading(false);
				});

			setProductTypesLoading(true);
			getProductTypes()
				.then((productTypes: IProductType[]) => {
					setProductTypes(
						productTypes.map((pt) =>
						// { label: pt.product_type || "", value: pt.id} // OLD FORMAT
						({
							label: pt.title || "",
							value: { category: pt.category, title: pt.title, id: pt.id }
						})
						)
					);
				})
				.finally(() => {
					setProductTypesLoading(false);
				});

			setCountryLoading(true);
			commonService
				.getAllMarkets()
				.then((countries: any[]) => {
					setCountries(countries?.map((c) => ({ label: c.name || "", value: c.id })));
				})
				.finally(() => {
					setCountryLoading(false);
				});

			setCurrencyLoading(true);
			getCurrencies()
				.then((currencies: any[]) => {
					setCurrencies(currencies.map((c) => ({ label: c.name || "", value: c.id })));
				})
				.finally(() => {
					setCurrencyLoading(false);
				});

			return function cleanup() {
				paginate(1); //Reset search pagination on component unmount
			};
		}, []);


		const isPortfolioIngredient = (id: string) => {
			if (!portfolioSearchResults) return false;
			return portfolioSearchResults.find((i: ISearchIngredient) => i.id == id) != undefined;
		};

		function handleManufacturerCreate(newManufacturer: string) {
			setManufacturers([
				...manufacturers,
				{
					label: newManufacturer,
					value: null
				}
			]);

			setFieldValue("manufacturer", {
				label: newManufacturer,
				value: null
			});
		}

		const handlePackageSelect = (packaging: IProductPackage) => {
			setSelectedPackage(packaging);
			setFieldValue("packaging", packaging);
		};

		const handleIngredientSearchClear = () => {
			setSearchIngredients([]);
			setPortfolioIngredients([]);
			setSearchTerm("");
			paginate(1);
		};



		return (
			<>
				{isAdmin && (
					<Row>
						<Col xs={12} md={12} lg={12}>
							<Label for="select_company">Select Company</Label>
							<SelectField
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								errors={errors}
								name="company"
								touched={touched}
								placeholder="Select Company"
								value={company}
								options={companies}
								isCreatable={true}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} md={12} lg={12}>
						<Label for="product_type">Product Type</Label>
						<SelectField
							// onCreate={handleManufacturerCreate}
							loading={productTypesLoading}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="product_type"
							touched={touched}
							isClearable={true}
							placeholder="Select Product Type"
							value={product_type}
							options={product_types}
							isCreatable={false}
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							label="SKU / Code"
							errors={errors}
							value={code}
							handleChange={handleChange}
							handleOnBlur={handleBlur}
							name="code"
							touched={touched}
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							label="Product Name"
							errors={errors}
							value={name}
							handleChange={handleChange}
							handleOnBlur={handleBlur}
							name="name"
							touched={touched}
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Label for="brand">Brand</Label>
						<SelectField
							loading={brandLoading}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="brand"
							touched={touched}
							isClearable={true}
							placeholder="Select Brand"
							value={brand}
							options={brands}
						/>
						{/* <ReactSelect
                        isLoading={brandLoading}
                        classNamePrefix="brand"
                        noOptionsMessage={(input: any) => {
                            return `Type to create a new brand ${input.inputValue}`;
                        }}
                        placeholder={(str: any) => {
                            return "Brand Name"
                        }}
                        menuPlacement="bottom"
                        formatCreateLabel={(name: any) => `Create brand "${name}"`}
                        onCreateOption={createBrand}
                        value={brand}
                        options={brands} /> */}
						<ErrorMessage name="brand" />
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={flavor}
							name="flavor"
							placeholder=""
							label="Flavor"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Label for="manufacturer">Manufacturer</Label>
						<SelectField
							onCreate={handleManufacturerCreate}
							loading={manufacturerLoading}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="manufacturer"
							touched={touched}
							isClearable={true}
							placeholder="Select Manufacturer"
							value={manufacturer}
							options={manufacturers}
							isCreatable={true}
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={created_date}
							type="date"
							name="created_date"
							label="Date Created"
						/>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							value={fulfillment_date}
							type="date"
							name="fulfillment_date"
							label="Fulfilment Date"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Label>Serving Size</Label>
						<div className="d-flex" style={{ height: 46 }}>
							<InputGroup className="serving_size">
								<InputGroupAddon addonType="prepend" style={{ width: "80px" }}>
									<SelectField
										placeholder="unit"
										errors={errors}
										customOnChange={(option: ValueType<any>) =>
											setFieldValue("unit_serving_size", option.value)
										}
										onChange={setFieldValue} // Unecessary required onChange handler, use above for proper formatting of selected value
										onBlur={handleBlur}
										touched={touched}
										name="unit_serving_size"
										options={["oz", "lb", "mg", "g", "kg", "mL"].map((unit) => ({
											label: unit,
											value: unit
										}))}
										value={{ label: unit_serving_size, value: unit_serving_size }}
										classNamePrefix={"test"}
									/>
								</InputGroupAddon>
								<Input
									name="serving_size"
									errors={errors}
									touched={touched}
									placeholder="Serving Size"
									type="number"
									handleOnBlur={handleBlur}
									handleChange={handleChange}
									value={serving_size.toString()}
									style={{
										height: "46px",
										borderTopLeftRadius: "6px",
										borderBottomLeftRadius: "6px",
										borderTopRightRadius: "0px",
										borderBottomRightRadius: "0px",
										borderRight: "none"
									}}
								/>
							</InputGroup>
						</div>
					</Col>
					<Col>
						<FormGroup>
							<Label>Product Status</Label>
							<SelectField
								name="product_status"
								options={[
									{ label: "Retail", value: true },
									{ label: "Concept", value: false }
								]}
								value={product_status}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								errors={errors}
								touched={touched}
							/>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							placeholder=""
							name="cost"
							value={cost}
							label="Cost"
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							placeholder=""
							name="markup"
							value={markup}
							label="Retail Cost"
						/>
					</Col>

					<Col xs={12} md={4} lg={4}>
						<Input
							handleChange={handleChange}
							errors={errors}
							touched={touched}
							handleOnBlur={handleBlur}
							placeholder=""
							disabled
							name="cost_margin"
							value={getCostMarkup(cost, markup)}
							label="Profit Margin (%)"
						/>
					</Col>
				</Row>

				<Row>
					<Col xs={12} md={4} lg={4}>
						<Label for="country">Country</Label>

						<SelectField
							loading={countryLoading}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="country"
							touched={touched}
							isClearable={true}
							placeholder="Select Country"
							value={country}
							options={countries}
						/>
					</Col>
					<Col xs={12} md={4} lg={4}>
						<Label for="currency">Currency</Label>
						<SelectField
							loading={currencyLoading}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							errors={errors}
							name="currency"
							touched={touched}
							isClearable={true}
							placeholder="Select Currency"
							value={currency}
							options={currencies}
						/>
					</Col>
				</Row>

				<FieldArray
					name={"ingredients"}
					render={({ form, remove, push }) => (
						<>
							<p className="mt-3">Ingredients</p>
							<div className="ingredients">
								{ingredients.map((item, index) => {
									if (true) {
										return (
											<div className="col-md-4 py-2">
												<div className="ingredient-item-container">
													<ReactImageFallback
														src={config.api.endpoints.ingredients.ingredientImage(
															item.ingredient.jf_display_name
														)}
														fallbackImage={getIngredientImagePlaceholder()}
														alt="Ingredient Image"
													/>

													<Label for={`ingredients.${index}.weight`}>
														{typeof item.ingredient === "string"
															? item.ingredient
															: item.ingredient.jf_display_name}
													</Label>
													<X className="remove-ingredient" size={15} onClick={() => remove(index)} />
												</div>

												<div className="d-flex">
													<InputGroup className="ingredient__detail">
														<InputGroupAddon addonType="prepend" style={{ width: "80px" }}>
															<SelectField
																placeholder="unit"
																errors={errors}
																onChange={setFieldValue}
																onBlur={setFieldTouched}
																touched={touched}
																name={`ingredients.${index}.unit`}
																options={["oz", "lb", "mg", "g", "kg", "mL"].map((unit) => ({
																	label: unit,
																	value: unit
																}))}
																value={ingredients[index].unit}
																classNamePrefix={"test"}
															/>
														</InputGroupAddon>
														<Input
															name={`ingredients.${index}.weight`}
															errors={errors}
															touched={touched}
															placeholder="Ingredient weight"
															type="number"
															handleOnBlur={handleBlur}
															handleChange={handleChange}
															value={ingredients[index].weight}
															style={{
																height: "39px",
																borderTopLeftRadius: "6px",
																borderBottomLeftRadius: "6px",
																borderTopRightRadius: "0px",
																borderBottomRightRadius: "0px",
																borderRight: "none"
															}}
														/>
													</InputGroup>
												</div>
											</div>
										);
									}
								})}
							</div>

							<ReactInput
								className="mt-2 px-3 py-2"
								style={{ height: 50, width: "100%", borderRadius: 5 }}
								placeholder="Search Ingredient to add..."
								onChange={handleSearchChange}
								value={searchTerm}
								autoComplete="off"
							/>
							{searchLoading ? (
								<Loader
									style={{
										position: "relative",
										top: -37,
										left: "96%"
									}}
									className="fa-spin"
								/>
							) : (
								<X
									style={{
										position: "relative",
										top: -37,
										left: "96%",
										cursor: "pointer"
									}}
									onClick={handleIngredientSearchClear}
								/>
							)}
							{
								<ListGroup>
									{uniqBy(
										[...portfolioIngredients, ...searchIngredients],
										(i: ISearchIngredient) => i.id
									)
										.map(
											(ingredient) =>
												!ingredients.find((item) => item.ingredient.id === ingredient.id) && (
													<ListGroupItem
														key={ingredient.id}
														className="border-0"
														onClick={() =>
															push({
																ingredient,
																weight: 0,
																base: 0,
																unit: {
																	label: unit_serving_size,
																	value: unit_serving_size
																}
															})
														}
													>
														<IngredientItem
															mini
															portfolioItem={isPortfolioIngredient(ingredient.id)}
															name={`${ingredient.jf_display_name} (${ingredient.manufacturer.name})`}
															onClick={() => undefined}
														/>
													</ListGroupItem>
												)
										)
										.filter((item) => item)}
									{pagination.page * pagination.size < pagination.total &&
										searchIngredients.length > 0 ? (
										<div style={{ display: "flex", justifyContent: "center" }}>
											<Button
												disabled={searchLoading}
												style={{ width: "max-content" }}
												onClick={() => paginate(pagination.page + 1)}
											>
												{searchLoading ? <Loader size={14} className="fa-spin" /> : "Load More"}
											</Button>
										</div>
									) : (
										<></>
									)}
								</ListGroup>
							}
							{isSearchCompleted &&
								!searchIngredient.length &&
								!searchLoading &&
								searchTerm.length ? (
								<div className="custom-ingredient">
									<a
										onClick={() => {
											push({
												ingredient: searchTerm,
												weight: 0,
												base: 0,
												unit: {
													label: "oz",
													value: "oz"
												}
											});
											setSearchTerm("");
										}}
									>
										Ingredient <strong>{searchTerm}</strong> does not exist. Create it ?
									</a>
								</div>
							) : null}
						</>
					)}
				/>
				{/* <p className="mt-1">Packaging</p>
			{(packaging || selectedPackage) && (
				<div className={"mb-2 d-flex flew-row align-items-center"} style={{ padding: "8px 15px" }}>
					<img
						src={packaging?.image_uri || selectedPackage?.image_uri || PackageItemPlaceholder}
						height={30}
						width={30}
						style={{ margin: "0 5px" }}
					/>
					<span>{selectedPackage?.name || packaging?.name}</span>
					<X
						size={16}
						className="ml-2"
						style={{ stroke: "currentColor" }}
						onClick={() => {
							setSelectedPackage(undefined);
							setFieldValue("packaging", undefined);
						}}
					/>
				</div>
			)} */}
				{/* <PackageSearch selected={selectedPackage} setSelectedPackage={handlePackageSelect} /> */}
				<Label for="country">Product Objectives</Label>
				<SelectField
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					errors={errors}
					name="objective"
					touched={touched}
					isClearable={true}
					multi
					placeholder="Select Product Objectives"
					value={objective}
					isCreatable={false}
					options={[
						{ label: "Cost", value: "Cost" },
						{ label: "Sustainability", value: "Sustainability" },
						{ label: "Nutrition", value: "Nutrition" },
						{ label: "Taste", value: "Taste" },
						{ label: "Texture", value: "Texture" }
					]}
				/>
				<Input
					style={{ height: 150 }}
					type="textarea"
					handleOnBlur={handleBlur}
					handleChange={handleChange}
					name="notes"
					touched={touched}
					label="Notes"
					value={notes}
					errors={errors}
				/>
			</>
		);
	};
// const mapStateToProps = ({ company: { companies }, ingredient: { search } }: IApplicationState, state: IApplicationState) => ({
const mapStateToProps = (state: IApplicationState) => ({
	companies: state.company.companies.map((item) => ({
		label: `${item.name} (${item.email})`,
		value: item
	})),
	searchResults: state.search.paginated_results,
	portfolioSearchResults: state.ingredient.searchIngredients
});

const mapDispatchToProps = {
	searchIngredient: (name: string) => ingredientService.searchIngredient(name),
	searchPortfolioIngredients: (term?: string) => ingredientService.getIngredients(term),
	getBrands: () => userService.getBrands(),
	getManufacturers: () => userService.getManufacturers(),
	getCurrencies: () => userService.getCurrencies(),
	getProductTypes: () => productTypeService.getProductTypes(),
	getSearchResults: (term: string) => searchService.paginateSearch(term),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormElements);
