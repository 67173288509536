import React, { useEffect, useState } from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { connect } from "react-redux";
import ProductFormElements, { IProductFormElements } from "./productFormElements";
import { ProductValidation } from "../../../../validations/productValidation";
import { IProduct } from "../../../../interfaces/products";
import { Form } from "reactstrap";
import Button from "../../../../components/common/button";
import { Save } from "react-feather";
import moment from "moment";
import { formatProductData, formatProductFormData } from "../../../../utils/product";
import productService from "../../../../services/productService";
import { IApplicationState } from "../../../../redux/reducers";
import searchService from "../../../../services/searchService";
import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import { search } from "../../../../redux/actions/actionContants";
import { Dispatch } from "redux";

interface IAddProductFormProps {
	add: (productData: any) => Promise<any>;
	company: any;
	toggleForm: () => void;
	generate: (productData: any) => Promise<any>
}

const AddProductForm: React.FC<InjectedFormikProps<IAddProductFormProps, any>> = (props) => {

	const [loadingButton, setLoadingButton] = useState<string | null>(null);

	// Custom function to handle button click and set loading state
	const onButtonClick = async (type: string) => {
		setLoadingButton(type); // Set loading button state
		props.setFieldValue("buttonType", type); // Pass button type to Formik
		props.handleSubmit(); // Trigger form submission
	};

	// const generateProduct = async () => {
	// 	const errors = await props.validateForm(); // Run validation
	// 	if (Object.keys(errors).length === 0) { // Check if there are any errors
	// 		setIsGenerating(true); // Start the loader
	// 		props.values.company = props.company;
	// 		const formattedData = formatProductFormData(props.values);
	// 		await props.generate(formattedData);
	// 		props.toggleForm();
	// 		props.resetForm();
	// 		setIsGenerating(false); // Stop the loader
	// 	} else {
	// 		// Optional: Scroll to the first error or show an error message
	// 		props.setErrors(errors);
	// 		console.log("Validation failed:", errors);
	// 	}
	// };

	return (
		<Form className="product-form">
			<ProductFormElements {...props} />
			<br />
			<hr />
			{/* <span className="save">
				<Button
					className="color-white mr-2"
					onClick={() => setLoadingButton('add')}
					color="primary"
					loading={loadingButton == 'add'}
					disabled={props.isSubmitting || !!loadingButton}
				>
					<Save size={18} className="mr-2" />
					Add
				</Button>
				<Button
					className="color-white mr-2"
					// onClick={props.handleSubmit}
					onClick={() => setLoadingButton('generate')}
					color="success"
					// loading={isGenerating} // Show loading for the Generate button
					loading={loadingButton == 'generate'}
					disabled={props.isSubmitting || !!loadingButton} // Disable if either button is loading
				// loading={props.isSubmitting}
				// disabled={props.isSubmitting}
				>
					<Save size={18} className="mr-2" />
					Generate
				</Button>
			</span> */}
			<span className="save">
				<Button
					className="color-white mr-2"
					onClick={() => onButtonClick("add")}
					color="primary"
					loading={loadingButton === "add" && props.isSubmitting}
					disabled={props.isSubmitting}
				>
					<Save size={18} className="mr-2" />
					Add
				</Button>
				<Button
					className="color-white mr-2"
					onClick={() => onButtonClick("generate")}
					color="success"
					loading={loadingButton === "generate" && props.isSubmitting}
					disabled={props.isSubmitting}
				>
					{/* <Save size={18} className="mr-2" /> */}
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="mr-1" fill="white" viewBox="0 0 18 18">
						<path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
					</svg>
					Generate
				</Button>
			</span>
		</Form>
	);
};

const WrappedForm = withFormik<IAddProductFormProps, IProductFormElements>({
	mapPropsToValues: ({ company }) => {
		return {
			cost: 0,
			markup: 0,
			cost_margin: 0,
			name: "",
			company,
			serving_size: 0,
			fulfillment_date: moment().format("YYYY-MM-DD"),
			created_date: moment().format("YYYY-MM-DD"),
			unit_serving_size: "oz",
			manufacturer: null,
			product_type: null,
			notes: "",
			country: null,
			currency: null,
			product_status: {
				label: "Concept",
				value: false
			},
			ingredients: [],
			flavor: "",
			brand: null,
			code: "",
			packaging: undefined,
			buttonType: ""
		};
	},
	handleSubmit: async function (values, { props, resetForm, setSubmitting, setFieldValue }) {
		// values.company = props.company;
		// const formattedData = formatProductFormData(values);

		// // await props.add(formattedData);
		// props.toggleForm();
		// resetForm();
		const formattedData = formatProductFormData(values);

		// Check the value of buttonType to determine the action
		if (values.buttonType === "add") {
			await props.add(formattedData);
		} else if (values.buttonType === "generate") {
			await props.generate(formattedData);
		}

		props.toggleForm();
		resetForm();
		setFieldValue("buttonType", ""); // Reset buttonType
		setSubmitting(false);
	},
	validationSchema: ProductValidation
});

const mapStateToProps = (state: IApplicationState) => {
	return {
		company: state.user.profile?.company || {}
	};
};

const mapDispatchToProps = {
	add: (productData: IProduct) => productService.addProduct(productData),
	generate: (productData: IProduct) => productService.generateProduct(productData)
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(AddProductForm));
