import { Api } from "./api";
import { Dispatch } from "redux";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import {
	getActiveProductResponseAction,
	getConceptProductResponseAction,
	getProductDetailResponseAction,
	getProductPersonaResponseAction,
	getProductVersionsResponseaAction,
	getProductPackagesAction,
	getRecentlyViewedAction
} from "../redux/actions/products/products";
import { IProduct } from "../interfaces/products";
import notification, { product } from "../utils/notification";
import { info } from "../utils/notification";

class ProductService extends Api {
	getActiveProducts() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					product: {
						active: { pagination }
					},
					user: {
						selected_brand: { id }
					}
				} = getState();

				const body: any = {
					params: {
						product_status: true,
						offset: pagination.page,
						limit: pagination.size
					}
				};
				if (id) body.params.brand = id;

				const response = await this.http.get(config.api.endpoints.products.active, body);
				dispatch(
					getActiveProductResponseAction({
						list: response.data.data,
						pagination: response.data.meta
					})
				);
			} catch (error) {
				this.handleError(error, "Active Product");
			}
		};
	}

	getConceptProducts() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					product: {
						concept: { pagination }
					},
					user: {
						selected_brand: { id }
					}
				} = getState();

				const body: any = {
					params: {
						product_status: false,
						offset: pagination.page,
						limit: pagination.size
					}
				};
				if (id) body.params.brand = id;

				const response = await this.http.get(config.api.endpoints.products.concept, body);
				dispatch(
					getConceptProductResponseAction({
						list: response.data.data,
						pagination: response.data.meta
					})
				);
			} catch (error) {
				this.handleError(error, "Concept Product");
			}
		};
	}

	getProductById(productId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			console.log(productId);
			try {
				let response = await this.http.get(config.api.endpoints.products.id(productId));
				if (!Array.isArray(response.data)) {
					response.data = [response.data];
				}
				console.log(response);
				const [active] = response.data.filter((product: IProduct) => product.active);
				const product = active ? active : response.data[0];
				dispatch(getProductDetailResponseAction(product));
				dispatch(getProductVersionsResponseaAction(response.data));

				return active || response.data[0];
			} catch (error) {
				notification.product("Product doesn't exist");
			}
		};
	}

	createNewProductVersion(masterProductId: string, newVersion: IProduct) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const version = (({ id, ...p }) => p)(newVersion); // exclude id
				const response = await this.http.post(
					config.api.endpoints.products.newProductVersion(masterProductId),
					version
				);
				window.location.reload();
				product("New product version successfully created.");
			} catch (error) {
				this.handleError(error, "Create New Product Version");
			}
		};
	}

	setCurrentVersion(productId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.put(config.api.endpoints.products.id(productId), {
					active: true
				});
				if (response.data) {
					await dispatch(this.getProductById(productId));
					product("Product version changed.");
				}
			} catch (error) {
				this.handleError(error, "Set Current Version");
			}
		};
	}

	duplicateProduct(productId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.post(config.api.endpoints.products.duplicate(productId));

				dispatch(getProductDetailResponseAction(response.data));
				dispatch(getProductVersionsResponseaAction([response.data]));

				product("Product successfully duplicated.");
			} catch (error) {
				this.handleError(error, "Duplicate Product");
			}
		};
	}

	getProductPersonasById(productId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.products.productPersona(productId)
				);
				dispatch(getProductPersonaResponseAction(response.data.top_personas));
			} catch (error) { }
		};
	}

	editProduct(productId: string, productData: IProduct, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.put(
					config.api.endpoints.products.id(productId),
					productData
				);
				dispatch(getProductDetailResponseAction(response.data));
				product("Product updated successfully");
			} catch (error) {
				this.handleError(error, "Edit Product");
			}
		};
	}

	addProduct(productData: any, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const { ...rest } = productData;
				const response = await this.http
					.post(config.api.endpoints.products.addProduct, rest)
					.then((res: any) => {
						if (res?.data?.id) {
							this.addRecentlyViewed(res?.data?.id)(dispatch, getState);
						}
						return res;
					});

				if (productData.product_status) {
					await this.getActiveProducts()(dispatch, getState);
				} else {
					await this.getConceptProducts()(dispatch, getState);
				}
				product("Product created successfully");
			} catch (error) {
				this.handleError(error, "Create Product");
			}
		};
	}

	generateProduct(productData: any, resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const { ...rest } = productData;
				const response = await this.http
					.post(config.api.endpoints.products.generate, rest)
					.then((res: any) => {
						if (res?.data?.id) {
							this.addRecentlyViewed(res?.data?.id)(dispatch, getState);
						}
						return res;
					});

				if (productData.product_status) {
					await this.getActiveProducts()(dispatch, getState);
				} else {
					await this.getConceptProducts()(dispatch, getState);
				}
				product("Product created successfully");
			} catch (error) {
				this.handleError(error, "Create Product");
			}
		};
	}

	removeProduct(productId: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.delete(
					config.api.endpoints.products.removeProducts(productId)
				);
				info("Product successfully removed.");
			} catch (error) {
				this.handleError(error, "Remove Product");
			}
		};
	}

	uploadProductImage(file: File, productId: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const formData = new FormData();
			formData.append("product_image", file);

			try {
				const response = await this.http.post(
					config.api.endpoints.products.imageUpload(productId),
					formData
				);

				info("Product image successfully uploaded.");
			} catch (error) {
				this.handleError(error, "Product Image");
			}
		};
	}

	createBrand(brand: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => { };
	}

	getProductPackages(productId: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.products.productPackages(productId)
				);
				dispatch(getProductPackagesAction(response.data));
			} catch (error) {
				// this.handleError(error, "Get Matched Packages")
			}
		};
	}

	addRecentlyViewed(id: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					product: { recentlyViewed }
				} = getState();
				if (!recentlyViewed.find((i: any) => i.id === id)) {
					const response = await this.http
						.post(config.api.endpoints.products.addRecentlyViewed(id))
						.then(() => this.getRecentlyViewed()(dispatch, getState));
				}
			} catch (error) {
				this.handleError(error, "Recently Viewed");
			}
		};
	}

	getRecentlyViewed() {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					user: {
						selected_brand: { id }
					}
				} = getState();
				const body: any = {
					params: {}
				};
				if (id) body.params.brand = id;

				const response = await this.http.get(config.api.endpoints.products.recentlyViewed, body);
				dispatch(getRecentlyViewedAction(response.data));
				return response.data;
			} catch (error) {
				return [];
			}
		};
	}

	getHarmonized(productId: string, firstIngredientId: string, secondIngredientId: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const body: any = {
					params: {
						product_id: productId,
						first_ingredient: firstIngredientId,
						second_ingredient: secondIngredientId
					}
				};

				const response = await this.http.get(config.api.endpoints.products.harmonized, body);

				return response.data;
			} catch (error) {
				return [];
			}
		};
	}

	shareProductWithTeam(id: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.post(
					config.api.endpoints.products.shareProductWithTeam(id)
				);
				return response;
				await this.getActiveProducts()(dispatch, getState);
			} catch (error) {
				this.handleError(error);
			}
		};
	}

	shareProductWithCompany(id: string, companyId: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response: any = await this.http.post(
					config.api.endpoints.products.shareProductWithCompany(id, companyId)
				);
				info(response?.data?.message);
				await this.getActiveProducts()(dispatch, getState);
			} catch (error) {
				this.handleError(error);
			}
		};
	}
	unShareProductWithTeam(id: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.patch(
					config.api.endpoints.products.shareProductWithTeam(id)
				);
				return response.data;
				await this.getActiveProducts()(dispatch, getState);
			} catch (error) {
				this.handleError(error);
			}
		};
	}

	unShareProductWithCompany(id: string, companies: []) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response: any = await this.http.patch(
					config.api.endpoints.products.unShareProductWithCompany(id),
					{ companies: companies }
				);
				await this.getActiveProducts()(dispatch, getState);
				info("Product unshared with all companies");
			} catch (error) {
				info("Something went wrong");
				this.handleError(error);
			}
		};
	}
}

export default new ProductService();
