import React, { useEffect, useState } from "react";
import { PlusSquare, X, ArrowLeft, Bookmark } from "react-feather";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { IRecommendation } from "../../../interfaces/recommendation";
import ingredientService from "../../../services/ingredientService";
import { connect, useDispatch } from "react-redux";
import RecommendationAnalysis from "./recommendationAnalysis";
import RecommendationSuppliers from "./recommendationSuppliers";
import RecommendationRate from "./recommendationRate";
import RecommendationSubmitted from "./recomendationSubmitted";
import RecommendationIngredientsInfo from "./recommendationIngredientsinfo";
import {
	IIngredient,
	IRecommendedIngredient,
	ISearchIngredient
} from "../../../interfaces/ingredient";
import { RouteComponentProps, withRouter } from "react-router-dom";

import RecommendationSupplierInfo from "./recomendationSupplierInfo";
import { RecommendationPortfolios } from "./recommendationPortfolio";
import { routes } from "../../routes";
import productService from "../../../services/productService";
import moment from "moment";
import { IProduct } from "../../../interfaces/products";
import adminService from "../../../services/adminService";

export enum recommendationStep {
	INGREDIENTS_INFO = "INGREDIENTS_INFO",
	ANALYSIS = "ANALYSIS",
	SUPPLIERS = "SUPPLIERS",
	SUPPLIER_INFO = "SUPPLIER_INFO",
	PORTFOLIO = "PORTFOLIO",
	RATE = "RATE",
	SUBMITTED = "SUBMITTED"
}

export enum analysisStep {
	COST_PREDICTION = "COST_PREDICTION",
	SUSTAINABILITY_ANALYSIS = "SUSTAINABILITY_ANALYSIS",
	NUTRITION_ANALYSIS = "NUTRITION_ANALYSIS"
}

export enum recommendationTypes {
	INGREDIENT_RECOMMENDATION = "Ingredient Recommendation",
	PACKAGING_RECOMMENDATION = "Packaging Recommendation",
	SUPPLIER_RECOMMENDATION = "Supplier Recommendation",
	PROCESS_RECOMMENDATION = "Process Recommendation"
}

const borderColor = {
	"ingredient-recommendation": "#ffbc10",
	"packaging-recommendation": "#395efa",
	"supplier-recommendation": "#53ce7c",
	"process-recommendation": "#C747F4"
};
interface IProps extends RouteComponentProps {
	isOpen: boolean;
	recommendation: IRecommendation;
	similarIngredients: Array<any>;
	editProduct: (productId: string, productData: any, isAdmin?: boolean) => Promise<any>;

	getRecommendedIngredients: (
		oldIngredientId: string,
		recommendIngredientId: string
	) => Promise<any>;
	getHarmonized: (
		productId: string,
		firstIngredientId: string,
		secondIngredientId: string
	) => Promise<any>;
	addRateRecommendation: (recommendationId: string, rate: any) => Promise<any>;
	addToPortfolio: (id: string) => Promise<any>;
	removeIngredient: (id: string) => Promise<any>;
	createNewProductVersion: (masterProductId: string, product: IProduct) => Promise<any>;
	product?: IProduct | null;
	role: string;
	toggle: () => void;
}

const formatIngredient = (ingredient: any) => ({
	base: ingredient.base,
	unit: ingredient.unit,
	ingredient:
		typeof ingredient.ingredient === "string"
			? ingredient.ingredient
			: {
					id: ingredient.ingredient.id
			  },
	weight: ingredient.weight
});

const RecommendationModal: React.FC<IProps> = ({
	isOpen,
	recommendation,
	similarIngredients,
	toggle,
	getRecommendedIngredients,
	getHarmonized,
	addRateRecommendation,
	addToPortfolio,
	removeIngredient,
	createNewProductVersion,
	editProduct,
	role,
	product,
	...props
}) => {
	const logo = require("../../../assets/images/recomendation/JourneyAI.png");
	const [expendedCard, setExpendedCard] = useState(recommendationStep.INGREDIENTS_INFO);
	const [rate, setRate] = useState({});
	const [harmonizations, setHarmonizations] = useState([]);

	const [recommendationNumber, setRecommendationNumber] = useState();

	const [activeRecommendation, setActiveRecommendation] = useState(
		recommendationTypes.INGREDIENT_RECOMMENDATION
	);
	const [expendedAnalysis, setActiveAnalysis] = useState(analysisStep.COST_PREDICTION);
	// const [selectedProductIngredient, setSelectedProductIngredient] = useState<ISearchIngredient>();

	const [oldIngredient, setOldIngredient] = useState<IIngredient>();
	const [recommendNum, setRecommendNum] = useState<number>();
	const [recommendedIngredient, setRecommendedIngredient] = useState<IIngredient>();

	const updateExpendedCards = (tabName: recommendationStep, analysisTabName?: analysisStep) => {
		setExpendedCard(tabName);
		if (analysisTabName) {
			setActiveAnalysis(analysisTabName);
		}
	};

	const updateRate = async (rate: any) => {
		try {
			await addRateRecommendation(recommendation.id, rate);
			setRate(rate);
			updateExpendedCards(recommendationStep.SUBMITTED);
		} catch {}
	};

	const createNewProductVersionWithIngredient = async () => {
		try {
			if (product && product?.ingredients) {
				const ingredientData = product?.ingredients
					.filter((item) => item.ingredient.id !== oldIngredient?.id)
					.filter((item) => typeof item.ingredient === "object")
					.map(formatIngredient);

				const data: any = {
					...product,
					product_status: false,
					ingredients: [
						...ingredientData,
						{
							base: 0,
							ingredient: { id: recommendedIngredient?.id },
							percentage: 0,
							unit: "",
							weight: 5
						}
					]
				};

				await createNewProductVersion(product.master_product_id, data);
			}
		} catch {}
	};
	useEffect(() => {
		setRate({
			rating: recommendation?.rating,
			rating_cost: recommendation?.rating_cost,
			rating_nutrition: recommendation?.rating_nutrition,
			rating_sustainability: recommendation?.rating_sustainability
		});
	}, [recommendation?.rating]);

	useEffect(() => {
		try {
			getRecommendedIngredients(
				recommendation.ingredient.id,
				recommendation.recommended_ingredients[0]?.id
			).then((res: any) => {
				if (res) {
					setRecommendationNumber(res.hash_number);
					setOldIngredient(res[recommendation.ingredient.id]);
					setRecommendedIngredient(res[recommendation.recommended_ingredients[0]?.id]);
				}
			});
		} catch {
			console.log("errors");
		}
	}, [recommendation.ingredient.id, recommendation.recommended_ingredients[0]?.id]);

	useEffect(() => {
		try {
			//
			// getHarmonized(
			// 	recommendation.product.id,
			// 	"5ef16374b3a431e671847cd8",
			// 	"5ef16374b3a431e671846c91"
			// ).then((res: any) => {
			// 	setHarmonizations(res);
			// 	console.log("getHarmonized", res);
			// });
			getHarmonized(
				recommendation.product.id,
				recommendation.recommended_ingredients[0]?.id,
				recommendation.ingredient.id
			).then((res: any) => {
				if (res.length)
					setHarmonizations(
						res?.filter((item: any) => item.master_product_id !== recommendation.product.id)
					);
			});
		} catch {
			console.log("errors");
		}
	}, [recommendation.ingredient.id, recommendation.recommended_ingredients[0]?.id]);

	const setHeaderClass = (recommendation: recommendationTypes) => {
		switch (recommendation) {
			case recommendationTypes.INGREDIENT_RECOMMENDATION: {
				return "ingredient-recommendation";
			}
			case recommendationTypes.PACKAGING_RECOMMENDATION: {
				return "packaging-recommendation";
			}
			case recommendationTypes.SUPPLIER_RECOMMENDATION: {
				return "supplier-recommendation";
			}
			case recommendationTypes.PROCESS_RECOMMENDATION: {
				return "process-recommendation";
			}
			default:
				return "ingredient-recommendation";
		}
	};

	return (
		<Modal isOpen={isOpen} className={"notification-modal recommendation-v2"}>
			<ModalHeader tag="div" className={"d-flex"}>
				<Row>
					<Col className={"card-header-label"} md="auto">
						<span className={setHeaderClass(activeRecommendation)}>{activeRecommendation}</span>
					</Col>

					{expendedCard !== recommendationStep.INGREDIENTS_INFO && (
						<Col className="ml-3">
							<span
								style={{
									fontStyle: "italic",
									textDecoration: "underline",
									cursor: "pointer",
									color: "#AAAAAA"
								}}
								onClick={() => {
									updateExpendedCards(recommendationStep.INGREDIENTS_INFO);
								}}
							>
								Back to overview
							</span>
						</Col>
					)}

					<Col className={"mr-4 d-flex justify-content-end"}>
						{recommendation?.rec_source === "SUGGESTED" ? (
							<div className="card-body-label suggested ml-2">Suggested</div>
						) : (
							<div className="card-body-label requested ml-2">Requested</div>
						)}
						{/* </div> */}

						{(role === "COMPANY_ADMIN" || role === "COMPANY_USER") && (
							<>
								<PlusSquare
									size={20}
									style={{
										cursor: "pointer",
										color: "#141C3F"
									}}
									className={"mr-4"}
									onClick={(e) => {
										if (product) {
											createNewProductVersionWithIngredient();
										}
										toggle();
									}}
									color={"#17C9DC"}
								/>
								{!recommendedIngredient?.is_user_ingredient ? (
									<Bookmark
										size={20}
										style={{
											cursor: "pointer",
											color: "#17C9DC"
										}}
										className={"mr-4"}
										onClick={(e) => {
											if (recommendedIngredient) addToPortfolio(recommendedIngredient?.id);
											toggle();
										}}
									/>
								) : (
									<Bookmark
										size={20}
										fill="#17C9DC"
										style={{
											cursor: "pointer",
											color: "#17C9DC"
										}}
										className={"mr-4"}
										onClick={(e) => {
											if (recommendedIngredient) removeIngredient(recommendedIngredient?.id);
											toggle();
										}}
									/>
								)}
							</>
						)}
						<div onClick={toggle} style={{ cursor: "pointer" }}>
							<X size={20} color={"#141C3F"} />
						</div>
					</Col>
				</Row>
			</ModalHeader>

			<ModalBody
				style={{
					borderBottom: `8px solid ${borderColor[setHeaderClass(activeRecommendation)]}`,
					borderRadius: "4px"
				}}
			>
				<>
					<Row className={"d-flex align-items-center justify-content-between"}>
						<Col className={"d-flex align-items-center justify-content-end"}></Col>
					</Row>
					<Row className="justify-content-between">
						<span style={{ color: "rgba(4, 25, 40, 0.5)" }}>
							{recommendation?.product?.name}
							{Boolean(recommendation?.product?.brand?.name) &&
								`in ${recommendation?.product?.brand?.name}`}{" "}
							{">"} Recommendation #{recommendationNumber}
						</span>
						<span style={{ color: "rgba(4, 25, 40, 0.5)" }}>
							Created Date : {moment(recommendation?.updated_date).format("YYYY-MM-DD")}
						</span>
					</Row>
					<Row className={"mb-3 mt-2"}>
						<div>
							<div className="d-flex mb-2">
								<div
									style={{
										fontSize: "20px",
										letterSpacing: "-0.4px",
										fontFamily: "Biotif",
										color: "#041928"
									}}
								>
									{" "}
									<b>Recommendation #{recommendationNumber}</b>
								</div>
								<div className="d-flex ml-3" style={{ gap: "10px", alignItems: "center" }}>
									<span
										style={{
											color: "#ffbc10",
											border: "1px solid #ffbc10",
											padding: "0px 4px",
											borderRadius: "5px"
										}}
									>
										Ingredient
									</span>
									<span
										style={{
											color: "#53ce7c",
											border: "1px solid #53ce7c",
											padding: "0px 4px",
											borderRadius: "5px"
										}}
									>
										Supplier
									</span>
									<span
										style={{
											color: "#C747F4",
											border: "1px solid #C747F4",
											padding: "0px 4px",
											borderRadius: "5px"
										}}
									>
										Process
									</span>
								</div>
							</div>
							<div
								style={{
									fontSize: "16px",
									letterSpacing: "-0.4px",
									lineHeight: "17px"
								}}
							>
								<span>
									Replace {oldIngredient?.jf_display_name} with{" "}
									{recommendation?.recommended_ingredients?.[0]?.jf_display_name} from{" "}
									<b>{recommendation?.recommended_ingredients?.[0]?.manufacturer?.name}</b> in{" "}
								</span>
								<span
									style={{ cursor: "pointer" }}
									onClick={() => {
										props.history.push(routes.PRODUCTS + `/${recommendation?.product.id}`);
									}}
								>
									<u>
										{Boolean(recommendation?.product?.brand?.name) &&
											`${recommendation?.product?.brand?.name}`}
										{recommendation?.product?.name}
									</u>
								</span>
								<span> , Version {recommendation?.product?.version}</span>
							</div>
						</div>
					</Row>
					{expendedCard === recommendationStep.ANALYSIS && (
						<Row className={"mt-2"}>
							<Col className={"d-flex justify-content-end"}>
								<div className={"d-flex align-items-center mr-3"}>
									<div className="circle gray"></div>
									<span className="pl-1">{recommendation.ingredient.jf_display_name}</span>
								</div>
								<div className={"d-flex align-items-center"}>
									<div className="circle purple"></div>
									<span className="pl-1">
										{recommendation?.recommended_ingredients?.[0]?.jf_display_name}
									</span>
								</div>
							</Col>
						</Row>
					)}
					{expendedCard === recommendationStep.INGREDIENTS_INFO &&
						oldIngredient &&
						recommendedIngredient && (
							<RecommendationIngredientsInfo
								harmonizations={harmonizations}
								oldIngredient={oldIngredient}
								recommendedIngredient={recommendedIngredient}
								recommendation={recommendation}
								onChangeExpendedCard={updateExpendedCards}
								history={props.history}
								toggle={toggle}
							/>
						)}
					{expendedCard === recommendationStep.ANALYSIS &&
						oldIngredient &&
						recommendedIngredient && (
							<RecommendationAnalysis
								oldIngredient={oldIngredient}
								recommendedIngredient={recommendedIngredient}
								activeRecommendation={activeRecommendation}
								activeAnalysis={expendedAnalysis}
							/>
						)}
					{expendedCard === recommendationStep.SUPPLIERS && (
						<RecommendationSuppliers
							onSupplierDetail={() => {
								updateExpendedCards(recommendationStep.SUPPLIER_INFO);
							}}
						/>
					)}
					{expendedCard === recommendationStep.SUPPLIER_INFO && <RecommendationSupplierInfo />}
					{expendedCard === recommendationStep.PORTFOLIO && (
						<RecommendationPortfolios
							editProduct={editProduct}
							newIngredientId={recommendation.recommended_ingredients[0]?.id}
							oldIngredientId={recommendation.ingredient.id}
							history={props.history}
							toggle={toggle}
							harmonizations={harmonizations}
						/>
					)}

					{expendedCard === recommendationStep.RATE && (
						<RecommendationRate
							rate={rate}
							onSubmit={(rating) => {
								updateRate(rating);
							}}
						/>
					)}
					{expendedCard === recommendationStep.SUBMITTED && (
						<RecommendationSubmitted
							onNavigateInfo={() => {
								updateExpendedCards(recommendationStep.INGREDIENTS_INFO);
							}}
						/>
					)}

					<Row className="recommendation-container">
						<Col style={{ display: "flex", alignItems: "center" }}>
							<img src={logo} alt="Logo" />
							<span style={{ marginLeft: 5 }}>
								{" "}
								This recommendation is from <i>JourneyAI</i>
							</span>
						</Col>
						<Col className={"d-flex justify-content-end"}>
							<h4
								onClick={() => {
									updateExpendedCards(recommendationStep.RATE);
								}}
							>
								Rate this recommendation
							</h4>
						</Col>
					</Row>
				</>
			</ModalBody>
		</Modal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		similarIngredients: state.ingredient.selectedSimiliarIngredients,
		role: state.user.role
	};
};

const mapDispatchToProps = {
	editProduct: (productId: string, productData: IProduct, isAdmin?: boolean) => {
		if (isAdmin) {
			return adminService.editAdminProduct(productId, productData);
		}
		return productService.editProduct(productId, productData);
	},
	getRecommendedIngredients: (oldIngredientId: string, recommendIngredientId: string) =>
		ingredientService.getCompareIngredients(oldIngredientId, recommendIngredientId),
	getHarmonized: (productId: string, firstIngredientId: string, secondIngredientId: string) =>
		productService.getHarmonized(productId, firstIngredientId, secondIngredientId),
	addRateRecommendation: (recommendationId: string, rate: any) =>
		ingredientService.addRateRecommendation(recommendationId, rate),
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	createNewProductVersion: (masterProductId: string, product: IProduct) =>
		productService.createNewProductVersion(masterProductId, product),
	removeIngredient: (ingredientId: string) => ingredientService.removeIngredient(ingredientId)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecommendationModal));
