import {
	IGetIngredientAction,
	IIngredientPagination,
	IGetStarredIngredientsAction,
	IStarIngredientAction,
	IGetIngredientDetailAction,
	IGetIngredientPersonaAction,
	IGetSimiliarIngredientsAction,
	IGetIngredientStatusAction,
	IGetRecentlyViewedAction,
	ISearchIngredientsAction
} from "./ingredientAction";
import { ingredients } from "../actionContants";
import { IIngredient, IIngredientPersonas } from "../../../interfaces/ingredient";
import { IPagination, IPaginateList } from "../../../interfaces/pagination";

export function GetIngredientsAction(payload: IPaginateList<IIngredient[]>): IGetIngredientAction {
	return {
		type: ingredients.INGREDIENTS_RESPONSE,
		payload
	};
}

export function GetIngredientSearchAction(payload: IIngredient[]): ISearchIngredientsAction {
	return {
		type: ingredients.INGREDIENTS_SEARCH_RESPONSE,
		payload
	};
}

export function SetIngredientPagination(payload: IPagination): IIngredientPagination {
	return {
		type: ingredients.SET_INGREDIENT_PAGINATION,
		payload
	};
}

export function getStarredIngredientsAction(payload: any): IGetStarredIngredientsAction {
	return {
		type: ingredients.GET_STARRED_INGREDIENTS_RESPONSE,
		payload
	};
}

export function starIngredientAction(payload: any): IStarIngredientAction {
	return {
		type: ingredients.STARRED_INGREDIENTS_RESPONSE,
		payload
	};
}

export function getIngredientDetailAction(payload: IIngredient | null): IGetIngredientDetailAction {
	return {
		type: ingredients.GET_INGREDIENT_DETAIL_RESPONSE,
		payload
	};
}

export function getIngredientPersonaAction(
	payload: IIngredientPersonas
): IGetIngredientPersonaAction {
	return {
		type: ingredients.GET_INGREDIENT_PERSONA_RESPONSE,
		payload
	};
}

export function getSimiliarIngredientsAction(payload: any): IGetSimiliarIngredientsAction {
	return {
		type: ingredients.GET_SIMILIAR_INGREDIENTS_RESPONSE,
		payload
	};
}

export function getIngredientStatusAction(payload: any): IGetIngredientStatusAction {
	return {
		type: ingredients.GET_INGREDIENT_STATUS_RESPONSE,
		payload
	};
}

export function getRecentlyViewedAction(payload: any): IGetRecentlyViewedAction {
	return {
		type: ingredients.GET_RECENTLY_VIEWED_INGREDIENT_RESPONSE,
		payload
	};
}

export function getIngredientUsageAction(payload: any) {
	return {
		type: ingredients.GET_INGREDIENT_USAGE,
		payload
	};
}

export function getIngredientPreviewAction(payload: any) {
	return {
		type: ingredients.GET_BUYERS_PREVIEW,
		payload
	};
}
