import { IUser } from "../../interfaces/user";
import { admin } from "../actions/actionContants";
import { IPagination } from "../../interfaces/pagination";
import { ITEMS_PER_PAGE } from "../../utils/constants";
import { IProduct } from "../../interfaces/products";
import { IEmailSchedule } from "../../interfaces/admin";

export interface ISearchState {
    result: any;
}

const INIT_STATE: IAdminState = {
    pending: [],
    users: [],
    products: {
        selected: null,
        products: [],
        pagination: {
            page: 1,
            pages: 0,
            size: ITEMS_PER_PAGE,
            total: 0
        }
    },
    requests: {
        list: [],
        pagination: {
            page: 1,
            pages: 0,
            size: ITEMS_PER_PAGE,
            total: 0
        }
    },
    reports: {
        list: [],
        pagination: {
            page: 1,
            pages: 0,
            size: ITEMS_PER_PAGE,
            total: 0
        }
    },
    emailSchedule: {
        day: 1,
        hour: 0,
        minute: 0,
        id: ""
    }
}

export interface IAdminState {
    pending: IUser[];
    users: IUser[];
    products: {
        selected: null | IProduct;
        products: any[];
        pagination: IPagination
    },
    requests: {
        list: any[];
        pagination: IPagination
    },
    reports: {
        list: any[];
        pagination: IPagination
    },
    emailSchedule: IEmailSchedule
};

export interface IAdminState {
    pending: IUser[];
    products: {
        selected: null | IProduct;
        products: any[];
        pagination: IPagination;
    };
    requests: {
        list: any[];
        pagination: IPagination;
    };
    reports: {
        list: any[];
        pagination: IPagination;
    };
    emailSchedule: IEmailSchedule;
}

export default function (state: IAdminState = INIT_STATE, action: any) {
    switch (action.type) {
        case admin.PENDING_USER_RESPONSE:
            return { ...state, pending: action.payload }
        case admin.ALL_USER_RESPONSE:
            return { ...state, users: action.payload }
        case admin.ADMIN_PRODUCTS_RESPONSE:
            return { ...state, products: { products: action.payload.products, pagination: action.payload.pagination } }
        case admin.SELECT_PRODUCT:
            return {
                ...state, products: {
                    ...state.products,
                    selected: action.payload
                }
            }
        case admin.PAGINATE_ADMIN_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    pagination: {
                        ...state.products.pagination,
                        page: action.payload
                    }
                }
            }
        case admin.ADMIN_REPORT_REQUESTS_RESPONSE:
            return {
                ...state,
                requests: {
                    list: action.payload.list,
                    pagination: action.payload.pagination,
                }
            }
        case admin.PAGINATE_ADMIN_REPORT_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    pagination: {
                        ...state.requests.pagination,
                        page: action.payload
                    }
                }
            }
        case admin.ADMIN_REPORTS_RESPONSE:
            return {
                ...state,
                reports: {
                    list: action.payload.list,
                    pagination: action.payload.pagination,
                }
            }
        case admin.PAGINATE_ADMIN_REPORTS:
            return {
                ...state,
                reports: {
                    ...state.reports,
                    pagination: {
                        ...state.reports.pagination,
                        page: action.payload
                    }
                }
            }
        case admin.EMAIL_SCHEDULE_RESPONSE:
            if (action.payload && typeof action.payload === 'object' && Object.keys(action.payload).length > 0) {
                return {
                    ...state,
                    emailSchedule: action.payload // Only set when payload is valid
                };
            }
        default:
            return state;
    }
}
