import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
import Supplier from "./component/supplier";
import supplierDetail from "./component/supplierDetail";

const SupplierRoute: React.FC = () => (
	<Switch>
		<Route path={routes.SUPPLIER_DETAIL} component={supplierDetail} />
		<Route path={routes.SUPPLIER} component={Supplier} />
	</Switch>
);

export default SupplierRoute;
