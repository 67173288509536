import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { ChevronRight } from "react-feather";
import { IProduct } from "../../../interfaces/products";
import { routes } from "../../routes";
import { confirmAlert } from "react-confirm-alert";
import { withFormik } from "formik";
import { connect } from "react-redux";

import moment from "moment";
import { formatProductFormData } from "../../../utils/product";
import { ProductValidation } from "../../../validations/productValidation";
import { IProductFormElements } from "../productDetail/form/productFormElements";
import adminService from "../../../services/adminService";
import productService from "../../../services/productService";
import { IApplicationState } from "../../../redux/reducers";
import { RouteComponentProps } from "react-router-dom";

interface ISupplierItemProps {
	id: string;
	title: string;
	cost: string;
	isAdmin?: boolean;
	sustainability: string;
	nutrition: string;
	item: any;
	editProduct: (productId: string, productData: any, isAdmin?: boolean) => Promise<any>;
	history: any;
	toggle: () => any;
	oldIngredientId: string;
	newIngredientId: string;
}

const PortfolioItem: React.FC<ISupplierItemProps> = ({
	item,
	id,
	title,
	cost,
	sustainability,
	nutrition,
	history,
	toggle,
	editProduct,
	oldIngredientId,
	newIngredientId,
	isAdmin
}) => {
	const [replace, setReplace] = useState<boolean>(false);
	console.log(item, oldIngredientId, newIngredientId);

	const formatProduct = () => {
		let newAddedIngredient = {};
		delete item?.created_by;
		delete item?.optimized_feature;
		delete item?.top_personas;
		item.ingredients = item.ingredients.filter((ingredient: any) => {
			if (ingredient.ingredient.id !== oldIngredientId) {
				return ingredient;
			}
		});
		item["newly_added_product_ingredient"] = [
			{
				base: 0,
				unit: "oz",
				weight: 0,
				ingredient: {
					id: newIngredientId
				}
			}
		];
	};

	function onReplaceHandler() {
		setReplace(true);

		confirmAlert({
			title: "Replace Ingredients",
			message: "Are you sure you want to replace this ingredient ? ",
			buttons: [
				{
					label: "Replace",
					onClick: async () => {
						if (item?.master_product_id) {
							formatProduct();
							await editProduct(item?.master_product_id, item, isAdmin);
						}
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}
	return (
		<Row className={"portfolio-content mt-4"}>
			<Col className={"name large"}>{title}</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{cost}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{sustainability}</span>
			</Col>
			<Col className={"text-center"}>
				<span className={"portfolio-content-box"}>{nutrition}</span>
			</Col>
			<Col style={{ gap: "0.5rem" }} className={"d-flex justify-content-center"}>
				<Button
					className={"view-button"}
					size="sm"
					outline
					onClick={() => {
						toggle();
						onReplaceHandler();
					}}
				>
					Replace <ChevronRight size={14} />
				</Button>
				<Button
					className={"view-button pr-2"}
					size="sm"
					outline
					onClick={() => {
						toggle();
						history.push(routes.PRODUCTS + `/${id}`);
					}}
				>
					View <ChevronRight size={14} />
				</Button>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state: IApplicationState, props: any) => ({
	profile: state.user.profile?.role === "JF_ADMIN"
});

interface ISuppliersProps {
	harmonizations: Array<IProduct>;
	history: any;
	toggle: () => any;
	oldIngredientId: string;
	newIngredientId: string;
	editProduct: (productId: string, productData: any, isAdmin?: boolean) => Promise<any>;
}

export const RecommendationPortfolios: React.FC<ISuppliersProps> = ({
	harmonizations,
	history,
	toggle,
	oldIngredientId,
	newIngredientId,
	editProduct
}) => {
	return (
		<Card className={"mt-3 pt-3"} style={{ paddingBottom: "125px" }}>
			<div
				style={{
					padding: "22px 0 35px 45px",
					fontFamily: "Inter",
					fontSize: "18px",
					fontWeight: "bold",
					color: "#041928"
				}}
			>
				Portfolio Harmonization
			</div>
			<Row className={"portfolio-content"}>
				<Col className={"portfolio-content-header large"}>Product</Col>
				<Col className={"portfolio-content-header"}>Cost</Col>
				<Col className={"portfolio-content-header"}>Sustainability</Col>
				<Col className={"portfolio-content-header"}>Nutrition</Col>
				<Col></Col>
			</Row>
			{harmonizations.map((element: any) => {
				return (
					<PortfolioItem
						item={element}
						editProduct={editProduct}
						id={element.master_product_id}
						key={element.master_product_id}
						title={element.name}
						cost={`${element.calculated_amount} %`}
						sustainability={`${element.sustainability_score} %`}
						nutrition={`${element.nutrition_score} %`}
						history={history}
						toggle={toggle}
						oldIngredientId={oldIngredientId}
						newIngredientId={newIngredientId}
					></PortfolioItem>
				);
			})}
		</Card>
	);
};

export default connect(mapStateToProps, null)(PortfolioItem);
