import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import { USER_ROLES } from "../../interfaces/roles";
import { RouteProps, Route, RouteComponentProps } from "react-router-dom";
import PermissionNotice from "../../routes/account/permissionNotice";

interface IAllowedRoles extends RouteProps {
	componentRoles?: string[];
	accessingAccount?: boolean;
	role: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const Permission: React.FC<IAllowedRoles> = ({
	component: Component,
	role,
	componentRoles,
	accessingAccount,
	...rest
}) => {
	return (
		<Route
			render={(props: RouteComponentProps) =>
				role === USER_ROLES.ADMIN || !componentRoles || componentRoles?.includes(role) ? (
					<Component {...props} />
				) : accessingAccount ? (
					<PermissionNotice />
				) : null
			}
			{...rest}
		/>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	role: state.user.role
});

export default connect(mapStateToProps)(Permission);
