import {
	IGetAllSupplierResponse,
	IGetPaginatedSupplierIngredientResponse,
	IGetSupplierIngredientResponse
} from "./supplier.d";
import { supplier } from "../actionContants";
import { IPaginateList } from "../../../interfaces/pagination";

export function getAllSupplierResponseAction(payload: any): IGetAllSupplierResponse {
	return {
		type: supplier.GET_ALL_SUPPLIER_RESPONSE,
		payload
	};
}

export function getSupplierIngredientResponseAction(
	payload: IPaginateList<any[]>
): IGetSupplierIngredientResponse {
	return {
		type: supplier.GET_SUPPLIER_INGREDIENT,
		payload
	};
}

export function getPaginateSupplierIngredientResponseAction(
	payload: IPaginateList<any[]>
): IGetPaginatedSupplierIngredientResponse {
	return {
		type: supplier.PAGINATE_SUPPLIER_INGREDIENT,
		payload
	};
}
