import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { IApplicationState } from "../../../redux/reducers";
import adminService from "../../../services/adminService";
import { IUser } from "../../../interfaces/user";
import { History } from "history";
import { Dispatch } from "redux";

import { ArrowRight, Share2, Star } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { IIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";
import ingredientService from "../../../services/ingredientService";
import portfolioService from "../../../services/preferenceService";

import config from "../../../utils/config";
import { TableSkeleton, ImageSkeleton } from "../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { toCamelCase } from "../../../utils/string";
import { routes } from "../../routes";
import Button from "../../../components/common/button";
import { RouteComponentProps } from "react-router-dom";
import IngredientRow from "./ingredientRow";
import SideForm from "../../../components/sideForm";
import EditIngredientForm from "../../../routes/ingredients/ingredientDetail/form/editForm";
import Pagination from "../../../components/pagination";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { ingredients } from "../../../redux/actions/actionContants";

interface IProps {
	getPendingRequests: (resolve?: Function, reject?: Function) => Promise<any>;
	approveOrReject: (userId: string, isApproved: boolean) => Promise<any>;
	pendingss: IUser[];
	editUser: (userId: string, userData: any) => Promise<any>;
	updateUserStatus: (userId: string, status: boolean) => Promise<any>;
	modifyUser: (userId: string, data: any) => Promise<any>;
	ingredients: IPaginateList<IIngredient[]>;
	fetchIngredients: () => Promise<any>;
	paginate: (page: number) => void;
	history: History;
	getIngredientById: (id: string) => Promise<any>;
	getIngredientPreferences: () => Promise<any>;
}

const AdminIngredients: React.FC<IProps> = ({
	ingredients: { list, pagination },
	paginate,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [editOpen, setEditOpen] = useState<boolean>(false);

	const toggleEditForm = () => {
		setEditOpen(!editOpen);
	};

	useEffect(() => {
		setLoading(true);
		props.fetchIngredients().finally(() => {
			setLoading(false);
		});
		props.getIngredientPreferences();
	}, [pagination.page]);

	const editHandler = (e: any, itemId: string) => {
		e.stopPropagation();
		setEditOpen(!editOpen);
		props.getIngredientById(itemId).finally(() => {
			setLoading(false);
		});
	};

	return (
		<div className="dash-list">
			<h4 className="mt-4">All Ingredients {pagination && pagination?.total}</h4>
			<Table borderless>
				<thead>
					<tr key="ingredient-list-header">
						{/* <th></th> */}
						<th>Name</th>
						<th>Category</th>
						<th>Last Edit Date</th>
						<th style={{ display: "inline-block", width: "fit-content" }}>Similar Ingredients</th>
						<th>Recommendation Priority Level</th>
						<th>Ranking</th>
						<th></th>
					</tr>
				</thead>
				{loading ? (
					<TableSkeleton />
				) : (
					<tbody>
						{/* { (props.allIngredients ? list : props.starredIngredients).map((i: any, index: any) =>  ( */}
						{list?.map((item: any, index: number) => (
							<tr
								onClick={() => props.history.push(`${routes.INGREDIENTS}/${item.id}`)}
								className="list-row"
								style={{ fontSize: "12px" }}
								key={item.id + index}
							>
								<td>{item.name}</td>
								<td>
									{item["food_group"] ? (
										<div className="d-flex" style={{ gap: "10px", flexDirection: "column" }}>
											{item["food_group"].map((category: any) => (
												<div
													style={{
														backgroundColor: "hsl(0,0%,90%)",
														borderRadius: "2px",
														padding: "5px",
														width: "fit-content",
														fontSize: "10px"
													}}
												>
													{category}
												</div>
											))}
										</div>
									) : (
										<>No Data</>
									)}
								</td>
								<td>{new Date(item.updated_at || item.created_date).toDateString().slice(3)}</td>
								<td style={{ display: "inline-block", width: "fit-content" }}>
									{item["similar_ingredients"]?.length ? (
										<div
											className="d-flex flex-column"
											style={{ gap: "5px", width: "fit-content" }}
										>
											{item["similar_ingredients"].map((item: any) => (
												<div
													style={{
														backgroundColor: "hsl(0,0%,90%)",
														borderRadius: "2px",
														padding: "5px",
														width: "fit-content",
														fontSize: "10px",
														cursor: "pointer"
													}}
													onClick={(e) => {
														e.stopPropagation();
														props.history.push(`${routes.INGREDIENTS}/${item.ingredient.id}`);
													}}
												>
													{item?.ingredient.jf_display_name}
												</div>
											))}
										</div>
									) : (
										<>No Data</>
									)}
								</td>
								<td>{item.recommendation_priority_level}</td>
								<td></td>
								<td>
									<div className="actions">
										<Button
											className="color-white approve"
											icon="fa fa-edit"
											onClick={(e) => editHandler(e, item.id)}
										/>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				)}
			</Table>
			<Pagination
				pages={pagination.pages}
				page={pagination.page}
				size={pagination.size}
				total={pagination.total}
				handlePageChange={paginate}
			/>
			<SideForm isOpen={editOpen} toggleForm={toggleEditForm} title="Edit Ingredient">
				<EditIngredientForm
					isUserIngredient={true}
					toggleForm={toggleEditForm}
					isOpen={editOpen}
					history={props.history}
					isAdminEdit={true}
				/>
			</SideForm>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	ingredients: state.ingredient.ingredients
});

const mapDispatchToProps = {
	fetchIngredients: () => ingredientService.getIngredients(),
	getPendingRequests: (resolve?: Function, reject?: Function) =>
		adminService.getPendingUsers(resolve, reject),
	approveOrReject: (userId: string, isApproved: boolean) =>
		adminService.approveOrRejectUser(userId, isApproved),
	editUser: (userId: string, userData: any) => adminService.updateUserById(userId, userData),
	updateUserStatus: (userId: string, status: boolean) =>
		adminService.updateUserStatus(userId, status),
	modifyUser: (userId: string, data: any) => adminService.modifyUser(userId, data),
	getIngredientById: (id: string) => ingredientService.getIngredientById(id),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(ingredients.PAGINATE_INGREDIENTS, page));
	},
	getIngredientPreferences: () => portfolioService.getFilters()
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminIngredients);
