import { History } from "history";
import { combineReducers } from "redux";
import { RouterState, connectRouter } from "connected-react-router";
import searchReducer, { ISearchState } from "./searchReducer";
import userReducer, { IUserState } from "./userReducer";
import ingredientReducer, { IIngredientState } from "./ingredientReducer";
import adminReducer, { IAdminState } from "./adminReducer";
import preferenceReducer, { IPreferenceState } from "./preferenceReducer";
import { user } from "../actions/actionContants";
import companyReducer, { ICompanyState } from "./companyReducer";
import dashboardReducer, { IDashboardState } from "./dashboardReducer";
import productReducer, { IProductState } from "./productReducer";
import personaReducer, { IPersonaState } from "./personaReducer";
import reportReducer, { IReportState } from "./reportReducer";
import billingReducer, { IBillingState } from "./billingReducer";
import analyticsReducer, { IAnalyticsState } from "./analyticsReducer";
import productTypeReducer, { IProductTypeState } from "./productTypeReducer";
import integrationReducer, { IIntegrationState } from "./integrationReducer";
import notificationReducer, { INotificationState } from "./notificationReducer";
import packagingReducer, { IPackagingState } from "./packagingReducer";
import recommendationReducer, { IRecommendationState } from "./recommendationReducer";
import supplierReducer, { ISupplierState } from "./supplierReducer";

export interface IReduxState {
	router: RouterState;
}

export interface IApplicationState {
	router: RouterState;
	search: ISearchState;
	user: IUserState;
	ingredient: IIngredientState;
	admin: IAdminState;
	preference: IPreferenceState;
	company: ICompanyState;
	dashboard: IDashboardState;
	product: IProductState;
	persona: IPersonaState;
	report: IReportState;
	analytics: IAnalyticsState;
	product_type: IProductTypeState;
	integration: IIntegrationState;
	notification: INotificationState;
	packaging: IPackagingState;
	billing: IBillingState;
	recommendations: IRecommendationState;
	supplier: ISupplierState;
}

export const createRootReducer = (history: History) => {
	const rootReducer = combineReducers({
		router: connectRouter(history),
		search: searchReducer,
		user: userReducer,
		ingredient: ingredientReducer,
		admin: adminReducer,
		preference: preferenceReducer,
		company: companyReducer,
		dashboard: dashboardReducer,
		product: productReducer,
		persona: personaReducer,
		report: reportReducer,
		analytics: analyticsReducer,
		product_type: productTypeReducer,
		integration: integrationReducer,
		notification: notificationReducer,
		packaging: packagingReducer,
		billing: billingReducer,
		recommendations: recommendationReducer,
		supplier: supplierReducer
	});
	return rootReducer;
};

export const rootReducer = (history: History) => (state: any, action: any) => {
	// clear redux store on logout action
	if (action.type === user.LOGOUT_REQUEST) {
		return createRootReducer(history)(undefined, action);
	}
	return createRootReducer(history)(state, action);
};
