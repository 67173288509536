import React, { useEffect, useState } from "react";
import { Label, Row, Col } from "reactstrap";
import Select from "react-select";
import { ChevronRight } from "react-feather";
import Button from "../../../../components/common/button";
import Loader from "../../../../components/loader";
import { ICompany } from "../../../../interfaces/company";
import { IApplicationState } from "../../../../redux/reducers";
import userService from "../../../../services/userService";
import { connect } from "react-redux";
import CustomSelect from "../../../../components/common/customSelect";
import companyService from "../../../../services/companyService";
import PermissionNotice from "../../permissionNotice";

interface IProps {
	updateCompanyPlantBased: (plant_based: string) => Promise<any>;
	getUserProfile: () => Promise<any>;
	updateCompanyTypes: (types: { id: string }[]) => Promise<any>;
	removeCompanyType: (id: string) => Promise<any>;
	getCompanyTypes: () => Promise<any>;
	company?: any;
	role?: any;
}

const Company: React.FC<IProps> = ({
	updateCompanyPlantBased,
	company,
	role,
	getUserProfile,
	getCompanyTypes,
	updateCompanyTypes,
	removeCompanyType
}) => {
	const [selectedTypes, setSelectedTypes] = useState<{ id: string }[]>([]);
	const [updating, setUpdating] = useState<boolean>(false);
	const [companyTypeOptions, setCompanyTypeOptions] = useState<any>([]);

	useEffect(() => {
		getCompanyTypes().then((res: any) => {
			setCompanyTypeOptions(res);
		});
	}, []);

	useEffect(() => {
		if (company.company_types) {
			setSelectedTypes(
				company.company_types.map((c: { id: string; title: string }) => {
					return { id: c.id };
				})
			);
		}
	}, [company]);

	const onSelectTypes = (s: any) => {
		const index = selectedTypes.findIndex((o) => o.id == s.id);
		if (index > -1) {
			const temp = [...selectedTypes];
			temp.splice(index, 1);
			setSelectedTypes(temp);
			removeCompanyType(s.id);
		} else {
			setSelectedTypes([...selectedTypes, { id: s.id }]);
			updateCompanyTypes([...selectedTypes, { id: s.id }]);
		}
	};

	const onPlantBasedChanged = (e: any) => {
		setUpdating(true);
		updateCompanyPlantBased(e.value)
			.then(() => getUserProfile())
			.finally(() => {
				setUpdating(false);
			});
	};

	return (
		<>
			{role !== "COMPANY_USER" ? (
				<div>
					<div
						style={{
							borderBottom: "1px dashed #cedbe9",
							paddingBottom: 15,
							paddingTop: 11
						}}
					>
						<Label className="color-battleship-grey">Company Type</Label>
						<div>
							{companyTypeOptions?.map((s: any) => (
								<>
									{selectedTypes.findIndex((o) => o.id === s.id) > -1 ? (
										<div
											style={{
												display: "inline-block",
												padding: 5,
												borderRadius: 8,
												marginRight: 6,
												marginBottom: 6,
												backgroundColor: "#607D8B",
												border: "2px solid #607D8B",
												cursor: "pointer",
												color: "#fff"
											}}
											onClick={() => onSelectTypes(s)}
										>
											<ChevronRight color="#fff" /> {s.title}
										</div>
									) : (
										<div
											style={{
												display: "inline-block",
												padding: 5,
												borderRadius: 8,
												marginRight: 6,
												marginBottom: 6,
												border: "2px solid #607D8B",
												cursor: "pointer"
											}}
											onClick={() => onSelectTypes(s)}
										>
											<ChevronRight color="#607D8B" /> {s.title}
										</div>
									)}
								</>
							))}
						</div>
					</div>
					<div
						style={{
							borderBottom: "1px dashed #cedbe9",
							paddingBottom: 15,
							paddingTop: 11
						}}
					>
						<Loader isLoading={updating}>
							<p>Plant Based</p>
							<CustomSelect
								placeholder={"Set plant based status..."}
								onChange={onPlantBasedChanged}
								options={[
									{ label: "Yes", value: "Yes" },
									{ label: "No", value: "No" },
									{ label: "Hybird", value: "Hybrid" }
								]}
								width={300}
								currentValue={{ label: company.plant_based, value: company.plant_based }}
								className={"mb-3"}
								cssStyles={{ border: "2px solid rgb(96, 125, 139)", borderRadius: 6 }}
							/>
						</Loader>
					</div>
				</div>
			) : (
				<PermissionNotice />
			)}
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company: state.user?.profile?.company,
	role: state.user?.profile?.role
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	getCompanyTypes: () => companyService.getCompanyTypes()
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
