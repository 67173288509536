import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { useDebounce } from "../../../../utils/hooks";
import { connect } from "react-redux";
import Company from "..";
import companyService from "../../../../services/companyService";
import { Loader, LogOut } from "react-feather";
import productService from "../../../../services/productService";
import userService from "../../../../services/userService";
import { IApplicationState } from "../../../../redux/reducers";

interface IProps {
	toggle: () => void;
	isOpen: boolean;
	shareProductWithCompany: (id: string, companyId: string) => Promise<any>;
	profile: any;
	logout: () => void;
	sendRequestToAdmin: () => Promise<any>;
}

const RequestAdmin: React.FC<IProps> = ({
	isOpen,
	toggle,
	profile,
	logout,
	sendRequestToAdmin
}) => {
	const [modal, setModal] = useState<boolean>(true);

	const requestAdmin = () => {
		sendRequestToAdmin();
	};

	return (
		<Modal isOpen={modal} centered>
			<ModalBody>
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<p
						className="d-flex flex-column align-items-center mb-1 mt-1"
						style={{ textAlign: "center" }}
					>
						<>
							<h3>
								<i className={"icon-nav-ingredients mr-2"} style={{ color: "#f4a850" }} />
								Welcome to Journey Foods
								<i className={"icon-nav-ingredients ml-2"} style={{ color: "#f4a850" }} />
							</h3>{" "}
							<h2> {profile?.first_name || "Ian"} !</h2>
							<br />
							<span style={{ fontSize: "16pt" }}>
								As you are a new user , please request Company Admin of{" "}
								{profile?.company.name || "Journey Foods"} to acess the features of application.
								<br />
							</span>
						</>
					</p>
					<div className="d-flex align-items-baseline">
						<Button
							className="mt-3 mr-3"
							style={{ backgroundColor: "#3f65f1", borderColor: "#3f65f1", color: "#fff" }}
							onClick={requestAdmin}
						>
							Request
						</Button>
						<Button style={{ height: "fit-content" }} className="log-out" onClick={logout}>
							Logout <LogOut size={16} />
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	isJFAdmin: state.user?.profile?.role === "JF_ADMIN",
	profile: state.user.profile
});

const mapDispatchToProps = {
	getCompanyName: (email: any) => companyService.getCompanyName(email),
	shareProductWithCompany: (id: string, companyId: string) =>
		productService.shareProductWithCompany(id, companyId),
	sendRequestToAdmin: () => userService.sendRequestToAdmin()
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestAdmin);
