import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { useDebounce } from "../../../../utils/hooks";
import { connect } from "react-redux";
import Company from "..";
import companyService from "../../../../services/companyService";
import { Loader } from "react-feather";
import productService from "../../../../services/productService";
import { IApplicationState } from "../../../../redux/reducers";

interface IProps {
	toggle: () => void;
	isOpen: boolean;
	getCompanyName: (email: any) => Promise<any>;
	shareProductWithCompany: (id: string, companyId: string) => Promise<any>;
	productId: string;
	companyName: string;
}

export const EMAIL_REGEX = new RegExp("[a-z0-9]+@[a-z]+[a-z]{2,3}.+[a-z]{2,3}");

const CompanyValidateModal: React.FC<IProps> = ({
	isOpen,
	toggle,
	getCompanyName,
	shareProductWithCompany,
	productId,
	companyName
}) => {
	const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
	const [email, setEmail] = useState<any>("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [loading, setIsLoading] = useState<boolean>(false);
	const [loadingShare, setIsLoadingShare] = useState<boolean>(false);

	const [companyResponse, setCompanyResponse] = useState<any>();
	const [isSameCompany, setIsSameCompany] = useState<boolean>(false);

	const shareProduct = () => {
		shareProductWithCompany(productId, companyResponse.id).finally(() => {
			setIsLoadingShare(false);
			toggle();
		});
	};

	const debouncedSearchTerm = useDebounce(email, 1000);

	const validateEmail = (emailInput: any) => {
		setIsSameCompany(false);
		setErrorMessage("*Invalid Email Address");
		setIsEmailValid(false);
		setIsLoading(false);
		if (EMAIL_REGEX.test(emailInput)) {
			setEmail(emailInput);
			setIsEmailValid(true);
			setErrorMessage("*Valid Email");
		}
	};

	useEffect(() => {
		if (isEmailValid) {
			setIsLoading(true);
			getCompanyName(email).then((res: any) => {
				setIsLoading(false);
				setCompanyResponse(res);
				res && res.id && setIsSameCompany(res.name === companyName);
			});
		}
	}, [debouncedSearchTerm, isEmailValid]);

	return (
		<Modal isOpen={isOpen} className="modal-dialog share-product-modal">
			<ModalHeader toggle={toggle}>Share Product with Company</ModalHeader>
			<ModalBody className="px-3 py-1">
				<Input
					type="email"
					placeholder="Enter an email which is belong to the company"
					style={{ width: "100%" }}
					className="p-2"
					onChange={(e) => validateEmail(e.target.value)}
				/>
				<p style={{ color: isEmailValid ? "green" : "red" }}>{errorMessage}</p>
			</ModalBody>

			<ModalBody className="mb-2" style={{ textAlign: "center" }}>
				{loading ? (
					<Loader className="fa-spin" />
				) : isEmailValid && companyResponse && companyResponse?.id ? (
					<div>
						<p style={{ color: "primary", textAlign: "center" }}>
							This email belongs to <h4>{companyResponse?.name}</h4>
						</p>
						{isSameCompany && (
							<p style={{ color: "red", textAlign: "center" }}>
								Sorry , you cannot share product with the same company.
								<u>
									<br></br>Please share by clicking on "Share with Team" button
								</u>
							</p>
						)}
					</div>
				) : (
					isEmailValid &&
					companyResponse &&
					companyResponse?.error && (
						<p style={{ color: "primary", textAlign: "center" }}>
							<h4>{companyResponse?.error}</h4>
						</p>
					)
				)}
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => {
						setIsLoadingShare(true);
						shareProduct();
					}}
					disabled={
						(isEmailValid && companyResponse && companyResponse?.error) ||
						isEmailValid === false ||
						isSameCompany ||
						loading ||
						loadingShare
					}
					color="primary"
				>
					Share
				</Button>
				<Button color="secondary" onClick={toggle}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	isJFAdmin: state.user?.profile?.role === "JF_ADMIN"
});

const mapDispatchToProps = {
	getCompanyName: (email: any) => companyService.getCompanyName(email),
	shareProductWithCompany: (id: string, companyId: string) =>
		productService.shareProductWithCompany(id, companyId)
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyValidateModal);
