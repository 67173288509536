import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";

import {
	getAllSupplierResponseAction,
	getSupplierIngredientResponseAction
} from "../redux/actions/supplier/supplier";

class SupplierService extends Api {
	getAllSupplierByUser() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const response = await this.http.get(config.api.endpoints.supplier.all);
				dispatch(getAllSupplierResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Supplier Details");
			}
		};
	}

	getSupplierIngredients(id: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					supplier: {
						ingredients: { pagination }
					}
				} = getState();
				const body: any = {
					params: {
						offset: pagination.page,
						limit: pagination.size
					}
				};
				const response = await this.http.get(
					config.api.endpoints.supplier.supplier_detail(id),
					body
				);
				dispatch(
					getSupplierIngredientResponseAction({
						list: response.data.data,
						pagination: response.data.meta
					})
				);
			} catch (error) {
				this.handleError(error, "Supplier Ingredient List");
			}
		};
	}
}

export default new SupplierService();
