import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import EmailScheduleFormElements, { IEmailScheduleFormElementsValues, WEEKDAYS } from "./EmailScheduleFormComponents";
import { Form } from "reactstrap";
import Button from "../../../../../components/common/button";
import { IEmailSchedule } from "../../../../../interfaces/admin";

interface IEmailScheduleFormProps {
    update: (schedule: IEmailSchedule) => Promise<any>;
    emailSchedule: IEmailSchedule;
}


const EmailScheduleForm: React.FC<InjectedFormikProps<IEmailScheduleFormProps, IEmailScheduleFormElementsValues>> = (props) => {


    return (
        <Form className="email-schedule-form" >
            <EmailScheduleFormElements {...props} />
            <Button loading={props.isSubmitting} block className="color-white mt-10" onClick={props.handleSubmit}>Update</Button>
        </Form >
    )

}

// const WrappedForm = withFormik<IEmailScheduleFormProps, IEmailScheduleFormElementsValues>({
//     enableReinitialize: true,
//     mapPropsToValues: ({ emailSchedule = { hour: '', minute: '', day: 0 } }) => ({
//         hour: emailSchedule.hour,
//         minute: emailSchedule.minute,
//         day: emailSchedule.day
//     }),
//     handleSubmit: async (values, { props, setSubmitting }) => {
//         setSubmitting(true);
//         await props.update({ day: values.day, hour: values.hour, minute: values.minute, id: props.emailSchedule.id });
//         setSubmitting(false);
//     },

// })
const WrappedForm = withFormik<IEmailScheduleFormProps, IEmailScheduleFormElementsValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ emailSchedule = { hour: '0', minute: '0', day: 0 } }) => ({
        hour: typeof emailSchedule.hour === 'string' ? parseInt(emailSchedule.hour, 10) : emailSchedule.hour,
        minute: typeof emailSchedule.minute === 'string' ? parseInt(emailSchedule.minute, 10) : emailSchedule.minute,
        day: typeof emailSchedule.day === 'string' ? parseInt(emailSchedule.day, 10) : emailSchedule.day,
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        console.log("SUBMITTED--->", values, props)
        setSubmitting(true);
        await props.update({ day: values.day, hour: values.hour, minute: values.minute, id: props.emailSchedule.id });
        setSubmitting(false);
    },
});

export default WrappedForm(EmailScheduleForm);