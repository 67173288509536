import * as React from "react";
import { Route, RouteProps, RouteComponentProps, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import { routes } from "../../routes/routes";
import Subscribe from "../../routes/subscribe";
import userService from "../../services/userService";
import { useEffect, useState } from "react";
import { user } from "../../redux/actions/actionContants";
import { IUserState } from "../../redux/reducers/userReducer";
import Loader from "../loader";
import RequestAdmin from "../../routes/account/company/components/requestAdmin";

export interface IDynamicRouteSegments {
	subLocationId: string;
}

interface PrivateRoutePropType extends RouteProps {
	isLoggedIn: boolean;
	user: IUserState | undefined;
	getUserProfile: () => Promise<any>;
	component: React.ComponentType<RouteComponentProps<IDynamicRouteSegments>>;
	logout: () => void;
}

const PrivateRoute: React.FC<PrivateRoutePropType> = ({
	component: Component,
	isLoggedIn,
	user,
	getUserProfile,
	logout,
	...rest
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [modal, setModal] = useState<boolean>();

	useEffect(() => {
		setLoading(true);
		getUserProfile().finally(() => {
			setLoading(false);
		});
	}, []);

	const isSubscribed = () => {
		return user?.profile?.company?.subscription_type != "" || user?.profile?.role === "JF_ADMIN";
	};

	const hasAccess = () => {
		return (
			user?.profile?.role === "COMPANY_ADMIN" ||
			user?.profile?.role === "JF_ADMIN" ||
			user?.profile?.has_access
		);
	};

	return (
		<Route
			{...rest}
			render={(props: RouteComponentProps<IDynamicRouteSegments>) =>
				isLoggedIn ? (
					loading ? (
						<Loader isLoading={loading} type={"spin"} fullScreen={true} />
					) : isSubscribed() ? (
						hasAccess() ? (
							<Component {...props} />
						) : (
							<RequestAdmin isOpen={true} toggle={() => setModal(!modal)} logout={logout} />
						)
					) : (
						<Subscribe />
					)
				) : (
					<Redirect to={routes.LOGIN} />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	isLoggedIn: state.user.isLoggedIn,
	user: state.user
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	logout: () => userService.logout()
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

// export default PrivateRoute;
