import { Api } from "./api";
import { Dispatch } from "redux";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import {
	GetIngredientsAction,
	getStarredIngredientsAction,
	getIngredientDetailAction,
	getIngredientPersonaAction,
	getSimiliarIngredientsAction,
	getIngredientStatusAction,
	getRecentlyViewedAction,
	GetIngredientSearchAction,
	getIngredientUsageAction,
	getIngredientPreviewAction
} from "../redux/actions/ingredients/ingredientActions";
import notification, { ingredient } from "../utils/notification";
import { info } from "../utils/notification";
import { IIngredientFormData } from "../interfaces/ingredient";

export class IngredientService extends Api {
	addIngredientToPortfolio(id: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				search: { term }
			} = getState();

			try {
				await this.http.post(config.api.endpoints.ingredients.addToPortfolio, {
					ingredients: [{ id }]
				});
				// fetch ingredients again
				await this.getIngredients(term)(dispatch, getState);
				notification.ingredient("Ingredient added to portfolio");
			} catch (error) { }
		};
	}

	addIngredient(ingredientData: any) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				search: { term }
			} = getState();

			try {
				await this.http.post(config.api.endpoints.ingredients.addToPortfolio, {
					...ingredientData
				});
				// fetch ingredients again
				await this.getIngredients(term)(dispatch, getState);
				notification.ingredient("Ingredient added sucessfully");
			} catch (error) { }
		};
	}

	getIngredients(searchTerm?: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				ingredient: {
					ingredients: { pagination }
				},
				user: { role }
			} = getState();

			try {
				const endpoint = searchTerm
					? config.api.endpoints.ingredients.searchPortfolio(role, searchTerm)
					: config.api.endpoints.ingredients.all(role);

				const response = searchTerm
					? await this.http.get(endpoint)
					: await this.http.get(endpoint, {
						params: {
							offset: pagination.page,
							limit: pagination.size,
							similar_ingredients: role === "JF_ADMIN"
						}
					});

				/**
				 * Data coming from a portfolio search is structured differently than w/out a search term provided;
				 * Response action parameter differences reflect this
				 */

				if (searchTerm) {
					if (response.data.length == 0) {
						dispatch(GetIngredientSearchAction([]));
						return;
					}
					dispatch(GetIngredientSearchAction(response.data));
				} else {
					dispatch(GetIngredientSearchAction([]));
					if (response.data.data.length == 0) {
						dispatch(
							GetIngredientsAction({
								list: [],
								pagination: {
									page: 1,
									pages: 1,
									size: 20,
									total: 0
								}
							})
						);
						return;
					}
					dispatch(
						GetIngredientsAction({
							list: response.data.data,
							pagination: response.data.meta
						})
					);
				}
			} catch (error) {
				this.handleError(error, `Ingredient ${searchTerm ? "Search" : ""} Error`);
			}
		};
	}

	getStarredIngredients() {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const response = await this.http.get(config.api.endpoints.ingredients.starred);
				const ingredients = Array.isArray(response.data) ? response.data : [];
				dispatch(getStarredIngredientsAction(ingredients));
			} catch (error) {
				this.handleError(error, "Starred Ingredients");
			}
		};
	}

	getIngredientFilterMenu(page: any, term: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				console.log("re");

				const response = await this.http.get(config.api.endpoints.ingredients.searchMenu(term));
				console.log("res", response);

				const ingredients = Array.isArray(response.data) ? response.data : [];

				// dispatch(getStarredIngredientsAction(ingredients));
			} catch (error) {
				this.handleError(error, "Starred Ingredients");
			}
		};
	}

	starIngredient(id: string, isStarred: boolean) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					ingredient: { starred }
				} = getState();

				let starredIngredients = [];

				if (!isStarred) {
					starredIngredients = [...starred, { id }].map((item) => ({
						id: item.id
					}));
				} else {
					starredIngredients = (starred as any)
						.map((item: any) => ({
							id: item.id
						}))
						.filter((item: any) => item.id !== id);
				}

				await this.http.post(config.api.endpoints.ingredients.starred, {
					ingredients: starredIngredients
				});

				const response = await this.http.get(config.api.endpoints.ingredients.starred);
				if (Array.isArray(response.data)) {
					dispatch(getStarredIngredientsAction(response.data));
					return;
				}
				dispatch(getStarredIngredientsAction([]));
			} catch (error) {
				this.handleError(error, "Star ingredient");
			}
		};
	}

	getIngredientById(id: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				dispatch(getIngredientDetailAction(null));
				const response = await this.http.get(
					config.api.endpoints.ingredients.id(id, getState().user.role)
				);

				dispatch(getIngredientDetailAction(response.data));

				return response.data;
			} catch (error) {
				notification.ingredient("Ingredient doesn't exist");
			}
		};
	}

	getIngredientStatus(ingredientId: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.ingredients.status(ingredientId));
				const { status } = response.data;
				dispatch(getIngredientStatusAction(status));
			} catch (error) {
				this.handleError(error, "Get Ingredient Status");
			}
		};
	}

	getIngredientPersona(ingredientId: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.ingredients.ingredientPersonas(ingredientId)
				);
				dispatch(getIngredientPersonaAction(response.data.top_personas));
			} catch (error) { }
		};
	}

	getSimliarIngredientsById(ingredientId: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.ingredients.similiarIngredients(ingredientId)
				);
				dispatch(getSimiliarIngredientsAction(response.data.similar_ingredients));
			} catch (error) { }
		};
	}

	getRecommendedIngredientsById(ingredientId: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.ingredients.recommendedIngredients(ingredientId)
				);
				return response.data;
			} catch (error) { }
		};
	}

	getCompareIngredients(firstIngredientId: string, secondIngredientId: string) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.ingredients.compareIngredients(firstIngredientId, secondIngredientId)
				);
				return response.data;
			} catch (error) {
				this.handleError(error, "Recommendation");
			}
		};
	}

	searchIngredient(name: string) {
		return async (dispatch: Dispatch) => {
			const response = await this.http.get(config.api.endpoints.ingredients.search(name));
			return response.data;
		};
	}

	removeIngredient(ingredientId: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			const {
				search: { term }
			} = getState();

			try {
				const response = await this.http.delete(
					config.api.endpoints.ingredients.removeIngredient(ingredientId)
				);
				await this.getIngredients(term)(dispatch, getState);
				info("Ingredient successfully removed.");
			} catch (error) {
				this.handleError(error, "Remove Ingredient");
			}
		};
	}

	addRateRecommendation(recommendationId: string, rate: any) {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.patch(
					config.api.endpoints.ingredients.rateRecommendation(recommendationId),
					{
						rating: rate.starNumber,
						rating_cost: rate.costNumber,
						rating_nutrition: rate.nutritionNumber,
						rating_sustainability: rate.sustainabilityNumber
					}
				);
				return response.data;
			} catch (error) {
				this.handleError(error, "Rating");
			}
		};
	}

	addRecentlyViewed(id: string) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					ingredient: { recentlyViewed }
				} = getState();
				if (!recentlyViewed.find((i: any) => i.id === id)) {
					const response = await this.http.post(
						config.api.endpoints.ingredients.addRecentlyViewed(id)
					);
				}
			} catch (error) {
				this.handleError(error, "Recently Viewed");
			}
		};
	}

	getRecentlyViewed() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.ingredients.recentlyViewed);
				dispatch(getRecentlyViewedAction(response.data));
				return response.data;
			} catch (error) {
				return [];
			}
		};
	}

	editIngredient(ingredient_id: string, ingredientData: IIngredientFormData) {
		return async (dispatch: Dispatch, getState: () => IApplicationState) => {
			try {
				const {
					search: { term }
				} = getState();
				const response = await this.http.put(
					config.api.endpoints.ingredients.edit(ingredient_id),
					ingredientData
				);
				dispatch(getIngredientDetailAction(response.data));
				ingredient("Ingredient updated successfully");
				await this.getIngredients(term)(dispatch, getState);
			} catch (error) {
				this.handleError(error, "Edit Ingredient");
			}
		};
	}

	uploadIngredientInfoFile(ingredient_id: string, file: File) {
		return async () => {
			try {
				const formData = new FormData();
				formData.append("ingredient_file", file);
				const response = await this.http.post(
					config.api.endpoints.ingredients.infoFileUpload(ingredient_id),
					formData
				);
				return response.data;
			} catch (error) {
				return error;
			}
		};
	}

	getIngredientUsage() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.analytics.ingredientUsage);
				dispatch(getIngredientUsageAction(response.data));
				return response.data;
			} catch (error) {
				return [];
			}
		};
	}

	getIngredientBuyersPreview() {
		return async (dispatch: Dispatch) => {
			try {
				const response = await this.http.get(config.api.endpoints.products.buyersPreview);
				dispatch(getIngredientPreviewAction(response.data));
				return response.data;
			} catch (error) {
				return [];
			}
		};
	}
}

export default new IngredientService();
