import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { CheckSquare, ChevronRight, Loader } from "react-feather";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import userService from "../../../services/userService";
import { routes } from "../../routes";
import ingredientService from "../../../services/ingredientService";
import { IIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

interface IProps {
	history: any;
	profile?: any;
	ingredientLoading?: boolean;
	ingredientUsage?: any;
	ingredients: IPaginateList<IIngredient[]>;
}

const BarChartContainer: React.FC<IProps> = ({
	ingredientUsage,
	ingredientLoading,
	profile,
	ingredients,
	history
}) => {
	const year = new Date().getFullYear();
	const [data, setData] = useState<any[]>();

	useEffect(() => {
		if (!ingredientLoading) {
			let tempData = [];
			for (const key in ingredientUsage[+year]) {
				tempData.push({ ...ingredientUsage[+year][key], month: key });
			}
			tempData.sort(function (a, b) {
				return months.indexOf(a.month) - months.indexOf(b.month);
			});
			setData(tempData);
		}
	}, [ingredientUsage]);

	return (
		<div className="chart-container">
			<h3 className="mr-4 font-weight-semibold">Ingredient Usage</h3>
			{ingredientLoading ? (
				<Loader className="fa-spin" />
			) : (
				<div className={"mt-4 mb-2"} style={{ height: 250, maxHeight: 250 }}>
					<Bar
						options={{
							responsive: true,
							scales: {
								yAxes: [
									{
										ticks: {
											beginAtZero: true,
											suggestedMin: 0,
											suggestedMax: ingredients.pagination.total ? ingredients.pagination.total : 10
										},
										gridLines: {
											display: false
										}
									}
								],
								xAxes: [
									{
										gridLines: {
											display: false
										}
									}
								]
							},
							tooltips: {
								callbacks: {
									label: function (tooltipItem: any, data: any) {
										return `Total:${ingredients.pagination.total}
										Used:${data["datasets"][0]["data"][tooltipItem["index"]]}
										${(
											(data["datasets"][0]["data"][tooltipItem["index"]] /
												ingredients.pagination.total) *
											100
										).toFixed(2)}%`;
									}
								}
							}
						}}
						data={{
							labels:
								data && data.length > 4
									? data.slice(data.length - 4, data.length).map((item) => item.month)
									: data?.map((item) => item.month),
							datasets: [
								{
									data:
										data && data.length > 4
											? data
													.slice(data.length - 4, data.length)
													.map((item) => +Object.keys(item).length - 2)
											: data?.map((item) => +Object.keys(item).length - 2),
									backgroundColor: "rgba(23, 201, 220, 0.75)",
									borderColor: ["#e1ecf3"],
									borderWidth: 1
								}
							]
						}}
						legend={{
							display: false
						}}
						height={200}
						width={260}
					/>
				</div>
			)}
			<div
				style={{ padding: "0.5rem 1rem", cursor: "pointer" }}
				className="d-flex justify-content-center mt-2"
			>
				<h5 className="font-weight-semibold">Analytics</h5>
				<ChevronRight size={20} />
			</div>

			{profile.phone_number === "" && (
				<div className="mt-2">
					<h5 className="font-weight-semibold">Your account is incomplete </h5>
					<span style={{ cursor: "pointer" }} onClick={() => history.push(`${routes.PROFILE}`)}>
						<CheckSquare />
						<span className="pl-2">Set Your Contact Information</span>
					</span>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile,
	ingredientUsage: state.ingredient.ingredientUsage,
	ingredients: state.ingredient.ingredients
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	getIngredientsUsage: () => ingredientService.getIngredientUsage()
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChartContainer);
