import React, { useState } from "react";
import { Check, Info, ArrowLeft, ArrowDown, X } from "react-feather";
import { Button, Card, CardBody, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import growth from "../../../../assets/images/subscription/growth.png";
import enterprise from "../../../../assets/images/subscription/enterprise.png";
import fresh from "../../../../assets/images/subscription/fresh.png";
import supplier from "../../../../assets/images/subscription/supplier.png";
import group from "../../../../assets/images/subscription/group.png";
import FeatureTable from "./featureTable";
import { IApplicationState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { IUser } from "../../../../interfaces/user";
import StripeClimate from "../../../../components/checkout/stripeClimate";
import SubscriptionCard from "../../../subscribe/components/subscriptionCard";
import { availableSubscriptionPlans, SUBSCRIPTION_OPTIONS } from "../../../../utils/constants";
import { toCamelCase } from "../../../../utils/string";
import SubscriptionCheckoutModal from "../../../../components/checkout/subscriptionModal";

interface IProps {
	profile: IUser | undefined;
}

const SubscriptionOptions: React.FC<IProps> = (props) => {
	const [selectedPlan, setSelectedPlan] = useState<availableSubscriptionPlans>();
	const [modal, setModal] = useState<boolean>();

	const handleSelectPlan = (plan: availableSubscriptionPlans) => {
		setSelectedPlan(plan);
		setModal(true);
	};

	return (
		<div className="subscription-options">
			<h3 className={"mb-3"}>
				<span style={{ color: "#3f65f1" }}>Pricing</span>
			</h3>
			<Row className={"mt-4"}>
				{Object.keys(SUBSCRIPTION_OPTIONS).map((key) => {
					const plan = SUBSCRIPTION_OPTIONS[key];
					return (
						<SubscriptionCard
							subscriptionName={plan.subscriptionName}
							subscriptionDetails={plan.subscriptionDetails}
							subscriptionPrice={plan.subscriptionPrice}
							subscriptionInterval={plan.subscriptionInterval}
							subscriptionDescription={plan.subscriptionDescription}
							dividerColor={plan.dividerColor}
							handleSelectPlan={handleSelectPlan}
						/>
					);
				})}
			</Row>
			<div className={"d-flex justify-content-center mt-3 mb-5"}>
				<div className="view-features">
					<a href="#features">View All Features</a>
					<ArrowDown />
				</div>
			</div>
			<Row id="features" className={"w-100"} style={{ display: "flex", flexDirection: "column" }}>
				<h3 style={{ alignSelf: "center", margin: "0.5rem" }}>All Features</h3>
				<FeatureTable />
			</Row>

			<Row
				className={"w-100 mt-3"}
				id="supplier-features"
				style={{ padding: "0 100px", display: "flex", flexDirection: "column" }}
			>
				<h3 className={"mb-3"} style={{ alignSelf: "center", margin: "0.5rem" }}>
					Supplier Pricing and Features
				</h3>
				<FeatureTable supplier />
			</Row>
			<Row className={"d-flex align-item-center justify-content-center pb-3"}>
				<div style={{ width: 500 }}>
					<StripeClimate />
				</div>
			</Row>
			{selectedPlan && (
				<Modal className="subscription-modal" isOpen={modal}>
					<ModalHeader>
						<div className={"modal-header-content"}>
							<span>{`Subscribe to ${toCamelCase(selectedPlan)} plan`}</span>
							<X
								onClick={() => {
									setModal(false);
								}}
								className={"close-modal"}
								size={24}
							></X>
						</div>
					</ModalHeader>
					<ModalBody>
						<SubscriptionCheckoutModal subscriptionPlan={selectedPlan} />
					</ModalBody>
				</Modal>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps)(SubscriptionOptions);
