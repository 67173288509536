import React, { useEffect, useState } from "react";
import { Loader, PlusCircle, Upload, User } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import { routes } from "../../routes";
import { Dispatch } from "redux";

import ReactImageFallback from "react-image-fallback";
import ingredientService from "../../../services/ingredientService";
import { IIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";
import { Col, Row, Table } from "reactstrap";
import { TableSkeleton } from "../../../utils/ghostUI";
import IngredientRow from "../../supplierDashboard/components/ingredientRow";
import supplierService from "../../../services/supplierService";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { supplier } from "../../../redux/actions/actionContants";
import { Pagination } from "../../../components/pagination/pagination";

interface IProps extends RouteComponentProps {
	history: any;
	profile: any;
	ingredients: IPaginateList<IIngredient[]>;
	fetchIngredients: () => Promise<any>;
	paginate: (page: number) => void;
	getSupplierIngredient: (supplierId: string) => Promise<any>;
	paginateSupplierIngredient: (page: number) => void;
}

const SupplierDetail: React.FC<IProps> = ({
	ingredients: { list, pagination },
	getSupplierIngredient,
	paginate,
	history,
	profile
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const supplierID = history.location.pathname.split("/")[3];
	const supplierDetail = profile?.find((item: any) => supplierID === item.id);

	useEffect(() => {
		setLoading(true);
		getSupplierIngredient(supplierID).finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	return (
		<div>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<h3 className="mr-4 font-weight-semibold">{supplierDetail?.name}'s Ingredient</h3>
					<h5 className="mr-4">Location : Austin , Texas</h5>
				</div>
				<Row
					style={{ flexWrap: "nowrap", width: "50%", justifyContent: "space-evenly" }}
					className={` border-radius-6 p-4 custom-shadow`}
				>
					<Col md="4">
						<div className="d-flex flex-column">
							<h4 className="mb-3">Nutrition</h4>
							<div className={"d-flex align-items-center"}>
								<svg
									width="21"
									height="21"
									viewBox="0 0 21 21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.4939 10.3454L6.80385 12.2746L5.3122 11.3323L3.82056 12.269L4.13535 10.3454L2.90039 8.996L4.58575 8.75066L5.31705 6.97754L6.0435 8.75066L7.7337 9.00157L6.4939 10.3454Z"
										stroke="#FF961C"
										stroke-width="1.25"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M13.4999 8.56665C13.4999 12.4908 10.6175 15.5122 7.24997 15.5122C3.88246 15.5122 1 12.4908 1 8.56665C1 4.64253 3.88246 1.62109 7.24997 1.62109C10.6175 1.62109 13.4999 4.64253 13.4999 8.56665Z"
										stroke="#FF961C"
										stroke-width="2"
									/>
									<path
										d="M19.3017 12.8044C19.3017 16.7286 16.4192 19.75 13.0517 19.75C9.68422 19.75 6.80176 16.7286 6.80176 12.8044C6.80176 8.88032 9.68422 5.85889 13.0517 5.85889C16.4192 5.85889 19.3017 8.88032 19.3017 12.8044Z"
										fill="white"
										stroke="#FF961C"
										stroke-width="2"
									/>
									<path
										d="M14.2283 14.5826L14.5382 16.5119L13.0466 15.5696L11.5549 16.5063L11.8697 14.5826L10.6348 13.2333L12.3201 12.988L13.0514 11.2148L13.7779 12.988L15.4681 13.2389L14.2283 14.5826Z"
										stroke="#FF961C"
										stroke-width="1.25"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>

								<h3 className="ml-3 mb-0">5</h3>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="d-flex flex-column">
							<h4 className="mb-3">Sustainability</h4>
							<div className={"d-flex align-items-center"}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M23.1999 3.1407C19.3484 2.58042 15.6419 2.99918 12.4743 4.44309C12.1756 2.86781 12.3156 1.3485 12.8745 0C7.18117 1.44304 2.63252 4.80849 0.581934 9.75821C-1.02844 13.6457 0.817994 18.1061 4.70718 19.7174C5.05663 19.8618 5.41102 19.9694 5.76627 20.0593C5.98348 21.1184 6.26333 22.169 6.64613 23.2C7.44328 23.6741 8.1879 23.2 8.6973 23.2C9.20669 23.2 8.01116 21.2666 7.77916 20.2878C8.6683 20.2681 9.54177 20.097 10.361 19.7818C13.1685 21.0505 16.5821 20.5369 18.8885 18.2317C20.6433 16.4758 21.5014 13.8211 21.0438 10.6871C20.5867 7.55073 21.4448 4.8952 23.1999 3.1407ZM7.62139 18.3666C7.55498 18.3666 7.49061 18.3495 7.42449 18.3468C6.99877 15.0266 7.45291 11.7047 8.69991 8.7L6.91437 7.95905C5.58443 11.1638 5.10768 14.5687 5.43886 17.9278C2.54639 16.7243 1.1686 13.3936 2.36804 10.4986C3.76845 7.11805 6.56058 4.47673 10.3709 2.86723C10.3671 4.82502 10.9523 6.84632 12.1097 8.787C13.3588 10.8814 13.6325 13.0352 12.88 14.8515C11.9943 16.9865 9.93095 18.3666 7.62139 18.3666ZM19.1303 10.9661C19.4836 13.3794 18.9123 15.4732 17.5217 16.8626C16.2048 18.1786 14.3717 18.7346 12.5932 18.4457C13.471 17.6923 14.1934 16.7324 14.6661 15.591C15.6155 13.2982 15.3928 10.5168 13.77 7.79694C13.4765 7.30365 13.2442 6.8092 13.0403 6.31533C15.03 5.3592 17.3091 4.8488 19.7898 4.8314C19.037 6.63868 18.8047 8.73016 19.1303 10.9661Z"
										fill={"#53CE7C"}
									/>
								</svg>
								<h3 className="ml-3 mb-0">10</h3>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="dash-list">
				<Table borderless>
					<thead>
						<tr key="ingredient-list-header">
							<th></th>
							<th>Name</th>
							<th>Certifications</th>
							<th>Datasheet</th>
							<th>Price/Unit</th>
						</tr>
					</thead>
					{loading ? (
						<TableSkeleton />
					) : (
						<tbody>
							{list?.map((i: any, index: number) => (
								<IngredientRow data={i} index={index} isDetail={false} />
							))}
						</tbody>
					)}
				</Table>
				{pagination.pages > 1 && (
					<Pagination
						pages={pagination.pages}
						page={pagination.page}
						size={pagination.size}
						total={pagination.total}
						handlePageChange={paginate}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.supplier.result,
	ingredients: state.supplier.ingredients
});

const mapDispatchToProps = {
	getSupplierIngredient: (supplierId: string) => supplierService.getSupplierIngredients(supplierId),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(supplier.PAGINATE_SUPPLIER_INGREDIENT, page));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierDetail));
