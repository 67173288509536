import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Card } from "reactstrap";
import { IProduct } from "../../../../interfaces/products";
import { IApplicationState } from "../../../../redux/reducers";
import ingredientService from "../../../../services/ingredientService";
import config from "../../../../utils/config";
import { ImageSkeleton } from "../../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";

interface IPreviewFieldProps {
	getPreviewIngredients: () => Promise<any>;
	previewIngredients: any;
}
const PreviewProduct: React.FC<IPreviewFieldProps> = ({
	getPreviewIngredients,
	previewIngredients
}) => {
	const [ingredientLoading, setIngredientLoading] = useState<boolean>(true);
	const projectImg = require("../../../../assets/images/dashboard/product.png");

	useEffect(() => {
		getPreviewIngredients().finally(() => {
			setIngredientLoading(false);
		});
	}, []);

	return (
		<div style={{ marginTop: "4rem" }}>
			<h3 className="mr-4 mb-4 font-weight-semibold">
				List of products where your ingredients are used
			</h3>
			<div className="preview-product-container dashboard-wrapper wrapper">
				{ingredientLoading ? (
					<Loader className="fa-spin" />
				) : previewIngredients.length ? (
					previewIngredients.map((product: any) => (
						<Card className="wrapper__card" style={{ height: "240px", width: "220px" }}>
							<div className="card__img" style={{ height: "140px" }}>
								<ReactImageFallback
									initialImage={<ImageSkeleton className={"table-img-ghost"} />}
									src={config.api.endpoints.ingredients.ingredientImage(product.name)}
									fallbackImage={projectImg}
									alt="Ingredient Image"
									className="mr-2 ml-2"
									style={{ objectFit: "contain" }}
								/>
							</div>
							<div className={`card__content p-2`}>
								<div className="content__heading">
									<span className="product__name">{product.name}</span>
									<span className="card__comment">By {product.company.name}</span>
									{product?.brand && (
										<span className="card__comment">Brand : {product?.brand.name}</span>
									)}
								</div>

								<div className="content__footer">
									<span
										style={{
											fontSize: "16px",
											fontStyle: "italic",
											color: "gray",
											letterSpacing: "0.1px",
											position: "absolute",
											bottom: "10px",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis"
										}}
									>
										{product.ingredient_used}
									</span>
								</div>
							</div>
						</Card>
					))
				) : (
					<>Oops , No one has used your ingredient in their products</>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state: IApplicationState) => ({
	previewIngredients: state.ingredient.buyersPreview
});

const mapDispatchToProps = {
	getPreviewIngredients: () => ingredientService.getIngredientBuyersPreview()
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewProduct);
