import React, { useEffect, useState } from "react";
import { Loader, PlusCircle, Upload } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SideForm from "../../../components/sideForm";
import { IApplicationState } from "../../../redux/reducers";
import { routes } from "../../routes";
import SupplierIngredientList from "./supplierIngredientList";
import AddIngredientForm from "../../ingredients/ingredientDetail/form/addForm";
import BarChartContainer from "./barChartContainer";
import ReactImageFallback from "react-image-fallback";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { toCamelCase } from "../../../utils/string";
import ingredientService from "../../../services/ingredientService";
import { IIngredient } from "../../../interfaces/ingredient";
import PreviewProduct from "../../products/productDetail/components/previewProduct";
import { IPaginateList } from "../../../interfaces/pagination";

interface IProps extends RouteComponentProps {
	history: any;
	getStarredIngredients: () => Promise<any>;
	getIngredientsUsage: () => Promise<any>;
	starIngredients: IIngredient[];
	ingredientUsage: any;
	ingredients: IPaginateList<IIngredient[]>;
}

const SupplierDashboard: React.FC<IProps> = ({
	history,
	getStarredIngredients,
	getIngredientsUsage,
	starIngredients,
	ingredientUsage,
	ingredients: { list, pagination }
}) => {
	const [addFormOpen, setAddFormOpen] = useState<boolean>(false);
	const [starredLoading, setStarredLoading] = useState<boolean>(false);
	const [ingredientLoading, setIngredientLoading] = useState<boolean>(false);
	const toggleAddForm = () => {
		setAddFormOpen(!addFormOpen);
	};

	useEffect(() => {
		setStarredLoading(true);
		getStarredIngredients().finally(() => {
			setStarredLoading(false);
		});
		getIngredientsUsage().finally(() => {
			setIngredientLoading(false);
		});
	}, []);

	return (
		<div>
			<div
				style={{ cursor: "pointer", width: "fit-content" }}
				className="title mb-2 d-flex"
				onClick={toggleAddForm}
			>
				<h3 className="mr-4 font-weight-semibold">Ingredient Portfolio</h3>
				<PlusCircle size={25} />
			</div>
			<div className="d-flex justify-content-between">
				<div style={{ width: "70%" }}>
					<SupplierIngredientList allIngredients={true} />
					<div className={`d-flex ${pagination?.pages === 1 && "justify-content-center"}`}>
						<span
							style={{ cursor: "pointer" }}
							className="d-flex"
							onClick={() => {
								history.push(routes.INTEGRATIONS_SOURCES);
							}}
						>
							<h5 className="mr-2 font-weight-semibold">Upload More Ingredients</h5>
							<Upload size={20} />
						</span>
					</div>

					<PreviewProduct />
				</div>
				<div className="right-side-container">
					<BarChartContainer history={history} ingredientLoading={ingredientLoading} />
					<div className="mt-4">
						<h3 className="mr-4 font-weight-semibold mb-4">Starred Ingredient</h3>
						{starredLoading ? (
							<Loader className="fa-spin" />
						) : starIngredients.length ? (
							starIngredients.map((item) => (
								<div
									className="mb-2 star-container"
									onClick={() => history.push(`${routes.INGREDIENTS}/${item.id}`)}
								>
									<ReactImageFallback
										src={
											item.image_url
												? item.image_url
												: config.api.endpoints.ingredients.ingredientImage(item.jf_display_name)
										}
										fallbackImage={getIngredientImagePlaceholder()}
										alt="Ingredient Image"
										className="mr-2 ml-2 "
										style={{ borderRadius: 6, height: "40px", width: "40px" }}
									/>
									<span className="text-center">{toCamelCase(item.jf_display_name)}</span>
								</div>
							))
						) : (
							<>No Ingredient is starred</>
						)}
					</div>
				</div>
			</div>
			<SideForm isOpen={addFormOpen} title="Add Ingredient" toggleForm={toggleAddForm}>
				<AddIngredientForm toggleForm={toggleAddForm} />
			</SideForm>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	subscription_type: state.user.profile?.company.subscription_type,
	starIngredients: state.ingredient.starred,
	ingredientUsage: state.ingredient.ingredientUsage,
	ingredients: state.ingredient.ingredients
});

const mapDispatchToProps = {
	getStarredIngredients: () => ingredientService.getStarredIngredients(),
	getIngredientsUsage: () => ingredientService.getIngredientUsage()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierDashboard));
