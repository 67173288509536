import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import IngredientsOverview from "../components/ingredientOverview";
import StarredIngredients from "../components/starred";
import AllIngredients from "../components/allIngredients";
import { IApplicationState } from "../../../redux/reducers";
import { USER_ROLES } from "../../../interfaces/roles";
import IngredientList from "../components/ingredientList";
import { Grid, List, Plus } from "react-feather";
import RecentlyViewed from "../../../components/common/recentlyViewed";
import SideForm from "../../../components/sideForm";
import { IUser } from "../../../interfaces/user";
import userService from "../../../services/userService";
import portfolioService from "../../../services/preferenceService";

import AddIngredientForm from "../ingredientDetail/form/addForm";
import { Button } from "reactstrap";
// class Ingredients extends React.Component<{ role: USER_ROLES }> {
// const [display, setDisplay] = useState<string>('list');

interface IProps {
	role: string;
	profile: IUser | undefined;
	getUserProfile: () => Promise<any>;
	getIngredientPreferences: () => Promise<any>;
}

const Ingredients: React.FC<IProps> = (props) => {
	const [display, setDisplay] = useState<string>("list");
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const [addFormOpen, setAddFormOpen] = useState<boolean>(false);
	const [showStarredIngredients, setShowStarredIngredients] = useState<boolean>(false);

	useEffect(() => {
		props.getIngredientPreferences();
		if (!props.profile) {
			setUserLoading(true);
			props.getUserProfile().finally(() => setUserLoading(false));
			return;
		}
		setUserLoading(false);
	}, []);

	const toggleAddForm = () => {
		setAddFormOpen(!addFormOpen);
	};

	return (
		<div className="ingredients">
			<IngredientsOverview
				handleStarredContent={() => {
					setShowStarredIngredients(true);
				}}
			/>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				{props.role === USER_ROLES.COMPANY_ADMIN || props.role === USER_ROLES.COMPANY_USER ? (
					<>
						<div style={{ width: "50%", marginRight: 10 }}>
							<StarredIngredients />
						</div>
						<div style={{ width: "50%", marginLeft: 10 }}>
							<RecentlyViewed type="ingredients" />
						</div>
					</>
				) : (
					<>
						<div style={{ width: "100%", marginLeft: 10 }}>
							<RecentlyViewed type="ingredients" />
						</div>
					</>
				)}
			</div>

			<div className="all-ingredients-container" style={{ marginTop: 20 }}>
				<hr
					style={{
						borderTop: "3px dashed #E1E9F1",
						marginTop: 45,
						marginLeft: -65,
						marginRight: -65,
						marginBottom: 0
					}}
				/>
				<div className={"display-options"}>
					<Grid
						size={24}
						className={display == "grid" ? "active display-icon" : "display-icon"}
						onClick={() => {
							setDisplay("grid");
						}}
					/>
					<List
						size={24}
						className={display == "list" ? "active display-icon" : "display-icon"}
						onClick={() => {
							setDisplay("list");
						}}
					/>
				</div>
				{display == "list" ? (
					<IngredientList
						allIngredients={true}
						showStarredIngredients={showStarredIngredients}
						handleStarredContent={() => {
							setShowStarredIngredients((prevVal: boolean) => !prevVal);
						}}
					/>
				) : (
					<div
						style={{
							backgroundColor: "#f4f7f9",
							padding: 45,
							paddingTop: 0,
							paddingLeft: 65,
							marginRight: -65,
							marginLeft: -65,
							marginBottom: -45
						}}
					>
						{/* < hr style={{ borderTop: '3px dashed #E1E9F1', marginTop: -45, marginLeft: -65, marginRight: -65 }} /> */}
						<AllIngredients />
					</div>
				)}
			</div>
			{props.profile?.role != "JF_ADMIN" && (
				<>
					<span className="action-button-group">
						<Button className="btn-circle btn-adder btn-secondary" onClick={toggleAddForm}>
							<Plus size={28} />
						</Button>
					</span>
					<SideForm isOpen={addFormOpen} title="Add Ingredient" toggleForm={toggleAddForm}>
						<AddIngredientForm toggleForm={toggleAddForm} />
					</SideForm>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	role: state.user.role,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	getIngredientPreferences: () => portfolioService.getFilters()
};
export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
