import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import Button from "../../../../components/common/button";
import { Save } from "react-feather";
import { IIngredientAddFormData, IIngredientFormData } from "../../../../interfaces/ingredient";
import IngredientFormElements from "./ingredientFormElements";
import { formatProductFormData } from "../../../../utils/product";
import { formatIngredientFormData } from "../../../../utils/ingredient";
import ingredientService from "../../../../services/ingredientService";
import { IApplicationState } from "../../../../redux/reducers";
import { IngredientValidation } from "../../../../validations/ingredientValidation";

interface IAddIngredientFormProps {
	toggleForm: () => void;
	profile?: any;
	add: (ingredientData: IIngredientAddFormData, profile: any) => Promise<any>;
	ingredientPreferance?: any;
}

const AddIngredientForm: React.FC<InjectedFormikProps<IAddIngredientFormProps, any>> = (props) => {
	return (
		<Form className="product-form">
			<IngredientFormElements ingredientPreferance={props.ingredientPreferance} {...props} />
			<br />
			<hr />
			<span className="save">
				<Button
					className="color-white mr-2"
					onClick={props.handleSubmit}
					color="primary"
					loading={props.isSubmitting}
					disabled={props.isSubmitting}
				>
					<Save size={18} className="mr-2" />
					Add
				</Button>
			</span>
		</Form>
	);
};

const WrappedForm = withFormik<IAddIngredientFormProps, IIngredientAddFormData>({
	mapPropsToValues: () => {
		return {
			name: "",
			jf_display_name: "",
			price_per_unit: "",
			food_group: [],
			available_markets: [],
			country_data: [],
			df_energy_kcal: "",
			df_protein: "",
			df_carbohydrate: "",
			df_fiber: "",
			df_sugar: "",
			df_calcium: "",
			df_vitamin_d2_d3: "",
			df_vitamin_c: "",
			df_cholesterol: "",
			df_fatty_acid_total_saturated: "",
			df_fatty_acid_total_trans: "",
			df_iron: "",
			df_potassium: "",
			df_sodium: "",
			datasheets: [],
			allergens: [],
			certifications: [],
			claim: "",
			regulation: "",
			upcycled: [],
			notes: "",
			plant_based: "No",
			is_upcycled: false,
			is_global: false,
			price_unit: "",
			water_footprint: "",
			greenhouse_gas_emissions: ""
		};
	},
	handleSubmit: async function (values, { props, resetForm }) {
		const formattedData = formatIngredientFormData(values, null);
		try {
			await props.add(formattedData, props.profile).then(props.toggleForm);
		} catch (error) {}
		resetForm();
	},
	validationSchema: IngredientValidation
});
const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile,
	ingredientPreferance: state.preference.preferences
});
const mapDispatchToProps = {
	add: (ingredientData: IIngredientAddFormData, profile: any) =>
		ingredientService.addIngredient({
			ingredients: [
				{
					...ingredientData,
					ingredient: ingredientData.jf_display_name,
					price: ingredientData.price_per_unit?.toString(),
					type_of_upload: "manual",
					manufacturer: {
						website: "",
						email: profile.email,
						image_url: "",
						name: profile.company.name,
						description: "",
						id: profile.id,
						address: "",
						phone: profile.phone_number
					}
				}
			]
		}),
	removeIngredient: (id: string) => ingredientService.removeIngredient(id)
};
export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm(AddIngredientForm));
