import React, { useEffect, useState } from "react";

import { IApplicationState } from "../../../../redux/reducers";
import userService from "../../../../services/userService";
import { connect } from "react-redux";
import companyService from "../../../../services/companyService";
import { product } from "../../../../redux/actions/actionContants";

import { paginateAction } from "../../../../redux/actions/pagination/pagination";
import productService from "../../../../services/productService";
import { Dispatch } from "redux";
import { History } from "history";
import ProductList from "./productList";

interface IProps {
	history: History;
}

const ShareProduct: React.FC<IProps> = ({ history }) => {
	return (
		<div>
			<div className="my-4">
				<h4>
					Wohooo , Now you can share products created by you along with users in your company or
					with different company.
				</h4>
			</div>
			<ProductList history={history} />
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company: state.user?.profile?.company,
	active: state.product.active,
	concept: state.product.concept
});

const mapDispatchToProps = {
	getActiveProducts: () => productService.getActiveProducts(),
	paginateActiveProducts: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(product.PAGINATE_ACTIVE_PRODUCT, page));
	},
	getUserProfile: () => userService.getUserProfile(),
	getCompanyTypes: () => companyService.getCompanyTypes()
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareProduct);
